<template>
  <!-- Horní navigační lišta -->
  <v-app-bar app color="primary" v-if="isMobile">
    <v-app-bar-title>Obsah</v-app-bar-title>
    <v-spacer></v-spacer>
    
    <!-- Mobilní menu - tři tečky -->
    <v-menu transition="slide-y-transition" eager close-on-content-click="false">
      <template v-slot:activator="{ props }">
        <v-btn icon v-bind="props">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-card width="250">
        <v-list>
          <v-expansion-panels :key="searchQuery">
            <v-expansion-panel
              v-for="(category, i) in filteredTopics"
              :key="i"
            >
              <v-expansion-panel-title @click.stop>{{ category.title }}</v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-list density="compact">
                  <v-list-item
                    v-for="(subtopic, j) in category.subtopics"
                    :key="j"
                    @click="selectTopic(subtopic.key)"
                    :class="{ active: selectedTopicKey === subtopic.key }"
                  >
                    <v-list-item-title class="text-body-2">{{ subtopic.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-list>
        <v-text-field
          v-model="searchQuery"
          label="Hledat v obsahu..."
          prepend-inner-icon="mdi-magnify"
          clearable
          variant="outlined"
          density="compact"
          class="search-field, ma-1"
          @click.stop
          hide-details="true"
        />
      </v-card>
    </v-menu>
  </v-app-bar>


  <!-- Levé menu pro desktop -->
  <v-navigation-drawer v-if="!isMobile" v-model="drawer" permanent>
    <v-list>
      <v-expansion-panels variant="accordion">
        <v-expansion-panel
          v-for="(category, i) in filteredTopics"
          :key="i"
        >
          <v-expansion-panel-title>{{ category.title }}</v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list density="compact">
              <v-list-item
                v-for="(subtopic, j) in category.subtopics"
                :key="j"
                @click="selectTopic(subtopic.key)"
                :class="{ active: selectedTopicKey === subtopic.key }"
              >
                <v-list-item-title class="text-body-2">{{ subtopic.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-list>
    <v-text-field
      v-model="searchQuery"
      label="Hledat v obsahu..."
      prepend-inner-icon="mdi-magnify"
      clearable
      variant="outlined"
      density="compact"
      class="search-field, ma-1"
      hide-details="true"
    />
  </v-navigation-drawer>

  <!-- Pravý obsah -->
    <v-container>
      <v-card>
        <v-card-title>{{ getTopicTitle }}</v-card-title>
        <v-card-text>
          <div v-html="highlightedContent"></div>
        </v-card-text>
      </v-card>
    </v-container>
</template>
    
<script setup>
import { ref, computed, onMounted, onUnmounted, watch, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";


const selectedTopicKey = ref(null);
// Funkce pro výběr tématu a zavření menu po výběru
const selectTopic = (key) => {
  selectedTopicKey.value = key;
};

const router = useRouter();
const route = useRoute();

// base url adresa serveru, odkud se načítají statické obrázky
const BaseUrl = ref(process.env.VUE_APP_API_STATIC_URL);

// Stav levého menu a výběr tématu
const drawer = ref(true);

// Seznam témat nápovědy
const helpTopics = ref([
  {
    title: "Registrace do robisu",
    subtopics: [
      { title: "Registrace oddílu", key: "reg-clubs" },
      { title: "Registrace účtů", key: "reg-account" },
      { title: "Pravomoce uživatelů", key: "reg-permmissions" }
    ]
  },
  {
    title: "Vše k soutěži",
    subtopics: [
      { title: "Vytvoření soutěže", key: "event-create" },
      { title: "Přihlášení na soutěž", key: "event-entry" },
      { title: "Hromadné přihlašování", key: "event-bulk-entry" },
      { title: "Přihlaš. organizátorů", key: "event-organizers-entry" },
      { title: "Editace soutěže", key: "event-edit" },
      { title: "Přidání etapy", key: "event-etap-create" },
      { title: "Správa kategorií", key: "event-category" },
      { title: "Startovné", key: "event-starting-fee" },
      { title: "Správci soutěže", key: "event-editors" },
      { title: "Zrušení soutěže", key: "event-canceled" },
      { title: "Volba startu", key: "event-start-choice" },
      { title: "Body na mapě", key: "event-map" },
      { title: "Editace přihlášených", key: "event-entry-edit" },
      { title: "Ubytování a strava", key: "event-food-acc" },
      { title: "Služby", key: "event-services" },
      { title: "Činovníci a organizátoři", key: "event-workers" },
      { title: "API klíč etapy", key: "event-apikey" },
      { title: "Nahrání start. a výs.", key: "event-startlist-results" },
      { title: "Pravomoce", key: "event-permissions" },
      { title: "Seznam XLS", key: "event-xls" },
      { title: "Automodul", key: "event-automodule" },
      { title: "Livelox", key: "event-livelox" }

    ]
  },
  {
    title: "Oddílová sekce",
    subtopics: [
      { title: "Správa členů", key: "club-members" },

    ]
  },
  {
    title: "Ostatní",
    subtopics: [
      { title: "Rozhodčí a trenéři", key: "other-referee-coach" },
      { title: "Žebříčky", key: "other-ranking" },

    ]
  },

  {
    title: "Pro vývojáře",
    subtopics: [
      { title: "Obecné informace", key: "dev-info" },
      { title: "SI Droid Event", key: "dev-sidroid" },
      { title: "Startovka", key: "dev-startlist" },
      { title: "Výsledky", key: "dev-results" },
      { title: "Online výsledky", key: "dev-online-results" },

    ]
  }
]);

// Mapování klíčů na obsah
const topicContent = {


  "reg-clubs": {
    title: "Jak registrovat oddíl",
    content: `<p>Nový oddíl může být vytvořen pouze administrátorem robisu. Oddíl může být i ze zahraniční. K založení je potřeba název oddílu a údaje předsedy oddílu, jehož účet administrátor založí spolu s oddílem. Předseda dostane všechny pravomoce na správu oddílu a členy si registruje sám, viz <a href="#" data-topic="reg-account">registrace účtů</a>.</p>`
  },


  "reg-account": {
    title: "Jak registrovat účet",
    content: `
        <p>Registrovat nové uživatele může pouze správce oddílu a to na stránce svého oddílu.<br>
          <a href="${BaseUrl.value}static/help/reg_button.jpg" target="_blank"><img src="${BaseUrl.value}static/help/reg_button.jpg" alt="registration icon" width="200px"></a><br>
          Pro registraci kompletní členské základny je vhodná hromadná registrace. Na každý řádek je nutné vložit povinné parametry přesně ve formátu: Příjmení;Jméno;index;email;rok narození;pohlaví;číslo SI. Pohlaví nabývá hodnot: <b>M</b> - muž, <b>F</b> - žena. <b>Email</b> a <b>číslo SI</b> není povinné. Např. Novotný;Petr;XXX8501;;1985;M; Zde není email a poslední parametr (číslo SI) uveden a člen se registruje bez emailu a čísla SI. <b>Pokud uživatel nemá vyplněný email, nemůže se do robisu přihlásit!</b> Oddělení parametrů musí být středníkem ; Nejlépe si tato data nachystáte v excelu. Každému uživateli bez vyplněného emailu při registraci se automaticky vygeneruje email ve tvaru index@rob-is.cz, protože ROBis neumí pracovat s uživatelem bez emailu.</p>
        <p>
          Pokud <b>mladší členové</b> nemají email, tak jej nevyplňujte. Email je pro každý účet totiž unikátní a tak nelze vyplnit email rodičů, kteří mají v robisu také svůj účet. Pokud rodiče účet v robisu nemají, tak jej samozřejmě vyplnit můžete. Na účet bez emailu se nebude dát přihlásit, protože heslo si uživatel vytváří právě přes email. <b>Předseda oddílu</b> ale může přes správu členů v oddíle <b>nastavit pro tyto uživatele účet</b>, který je bude moci přes sebe v robisu na soutěže přihlašovat. Takto mohou rodiče přes sebe přihlašovat své děti. Jakmile si mladý člen založí svůj email, předseda oddílu tento email uživateli zapíše a bude se moci na účet běžně přihlásit.<br> 
          <a href="${BaseUrl.value}static/help/club_person_assist_user_edit.png" target="_blank"><img src="${BaseUrl.value}static/help/club_person_assist_user_edit.png" width="600px" alt="přidání uživatle pro povolení editace jiného účtu"></a>
        </p>
        <p>
          Pokud při registraci <b>není vyplněný email</b>, automaticky se uživateli email vytvoří ve formátu index@rob-is.cz. Tento email však není pro uživatele použitelný a řeší jen technické otázky v ROBISu. Předseda oddílu jej může kdykoli změnit. 
        </p>
      
    `
  },


  "reg-permmissions": {
    title: "Pravomoce uživatelů",
    content: `
          <p>Barevně jsou rozlišeny možné role uživatelů robisu. Stejnou funkci může mít více uživatelů.</p>
        <h3>Manažer</h3>
        <ul>
            <li>Může editovat a vytvářet třídy rozhodčích a trenérů.</li>
        </ul>

        <h3>Předseda oddílu</h3>
        <ul>
            <li>Určuje uživatele pro oprávnění <span style="color:rgb(255, 123, 0)">organizátor</span> a <span style="color:rgb(255, 123, 0)">správce oddílu</span>.</li>
            <li>Předseda může: <b>spravovat údaje členům oddílu</b> (jméno, příjmení, email, index, číslo SI, rok narození, pohlaví), <b>vyloučit uživatele z oddílu</b> (odebrat jim index a příslušnost k oddílu), <b>smazat účty</b>, které jsou mladší jak 14 dní.</li>
            <li>Předseda může být v každém oddíle pouze jeden.</li>
        </ul>

        <h3>Správce oddílu</h3>
        <ul>
            <li>Vytváří registrace nových členů oddílu</li>
            <li>Může vytvářet nebo editovat přihlášky členů oddílu na soutěže</li>

            <!-- <li>Může spravovat automoduly v soutěži.</li>
            <li>Má k dispozici seznam lidí z oddílu přihlášených v jakékoli soutěži a může jej editovat, nebo další lidi na soutěž přihlásit.</li> -->
        </ul>
        <h3>Organizátor</h3>
        <ul>
            <li>Zakládá soutěže pod hlavičkou svého oddílu</li>
            <li>Má přístup ke všem funkcím v soutěžích pořádaných jeho oddílem</li>
        </ul>`
  },
  "event-create": {
    title: "Vytvoření soutěže",
    content: `
    <p>
      Pro vytvoření nové soutěže v kalendáři klikni na zelenou ikonku vlevo nahoře na hlavní stránce.<br>
      <a href="${BaseUrl.value}static/help/event_create.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_create.png" alt="ikona pro vytvoření soutěže" width="900px">
      </a><br>
      Vyplň všechna pole. Jsou povinná. Pokud pořádáš závody, vyber typ soutěže "Závod". V tomto případě ještě musíš povinně přidat minimálně jednu etapu, protože jinak nebudeš moci do robisu nahrát startovku ani výsledky. 
    </p>`
  },

  "event-edit": {
    title: "Jak editovat soutěž",
    content: `
    <p>
      Přístup k editaci je pro oprávněné uživatele přes stránku soutěže. V kalendáři si soutěž rozklikni a pokud můžeš editovat soutěž, tak vpravo nahoře uvidíš ozubené kolo. Přes něj se dostaneš do nastavení soutěže<br>
      <a href="${BaseUrl.value}static/help/event_edit_button.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_edit_button.png" alt="ikona pro vytvoření soutěže" width="900px">
      </a><br>
      S jakými pravomocemi a za jakých podmínek můžeš soutěž editovat se dozvíš v tématu <a href="#" data-topic="event-permissions">pravomoce</a>. 
    </p>`
  },

  "event-etap-create": {
    title: "Přidání etapy do vytvořené soutěže",
    content: `
    <p>
      V nastavení etapy lze přes tlačátko plus přidat další etapu. Pořadí ve zobrazení etap na stránce soutěže závisí na datumu etapy a na čase startu 00. Smazat etapu můžeš, pokud v ní nejsou přihlášení závodníci po kliknutí na etapu vpravo dole tlačítkem smazat etapu.<br>
      <a href="${BaseUrl.value}static/help/event_etap_create.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_etap_create.png" alt="ikona pro vytvoření etapy" width="900px">
      </a>
    </p>`
  },




  "event-category": {
    title: "Správa kategorií",
    content: `
    <p>
      V nastavení soutěže klikni na modré tlačítko spravovat kategorie hromadně (na stránce etapy: spravovat kategorie). Pokud spravuješ kategorie z hlavní stránky soutěže, můžeš spravovat jak kategorie pro soutěž (v přihláškách se zobrazí jako "Vyber hlavní kategorii"), tak pokud zaklikneš dole velde tlačítka uložit "přepsat i v etapách", veškerá data kategorií se přepíší i do všech etap v soutěži. Lze tak editovat kategorie najednou, např. pokud chceš zavést jen dvě. Pokud chceš změnit kategorie pouze v určité etapě, zvol danou etapu a klikni na tlačítko "spravovat kategorie".  
      <br>
      <a href="${BaseUrl.value}static/help/event_category.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_category.png" alt="" width="1000px">
      </a><br>
      S vytvořením soutěže se automaticky vytvoří kategorie, které jsou podle pravidel vypisované na soutěžích prvního stupně (MČR, NŽ).<br>
      
      <a href="${BaseUrl.value}static/help/event_category_dialog.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_category_dialog.png" alt="" width="600px">
      </a><br>

      <h3>Předvolba kategorie a název</h3>
      Přidat novou kategorii lze přes zelené tlačítko vpravo nahoře. V tabulce se vytvoří nový řádek. V prvním sloupečku se vybírá kategorie, jak je definovaná v pravidlech, aby se podle těchto pravidel mohl robis a jiné aplikace řídit. Čísla v závorce značí věk. Jedná se o standardní pravidla kromě: MDR (všechny pohlaví 0-13 let), Ostatní (všechny pohlaví 0-99 let), ORG (organizátoři) (všechny pohlaví 0-99 let). Kategorie ORG je zobrazována všem, kteří jsou ze stejného oddílu, jako je pořádající oddíl a slouží k přihlášení organizátorů pro lepší přehled. Kvůli přehlednosti se ženám nezobrazují mužské kategorie, i když to pravidla dovolují. Druhý sloupeček nese název kategorie, takže lze vytvořit mužskou kategorii s názvem H s tím, že v prvním sloupečku bude zvoleno M20 (0-99).
      <h3>Konkrétní příklady kdy co zvolit</h3>   
      Katgorie příchozích - [Ostatní (0-99)], Příchozí<br>
      Kategorie muži, jakýkoli věk - [M20 (0-99)], H<br>
      Kategorie ženy, jakýkoli věk - [D20 (0-99)], D<br>
      Kategorie junioři a méně (lze použe muži) - [M19 (0-19)], Mladší <br>
      Kategorie veteráni muži i ženy - [Ostatní (0-99)], Veteráni (kategorie se bohužel nabídne i mladším ročníkům MD20-)<br>
      Kategorie děti - [Ostatní (0-99)], Děti (kategorie se bohužel nabídne i starším ročníkům MD20+)<br>
      Kategorie pro přihlašování organizátorů z oddílu, jakýkoli věk a pohlaví, omezeno pouze pro pořádající oddíl - [ORG (0-99)], Organizátor<br>
      <br>
      Z technického hlediska nezlze udělat kategorii MD16 apod. protože robis nekombinuje pohlaví, kromě možnosti Ostatní, MDR a ORG.
      
      <h3>Startovné a zvýšené startovné</h3>
      Startovné lze zapsat hromadně pro všechny kategorie. Využij k tomu první řádek dialogového okna správce kategorií. najdeš tam pole "hromadné startovné", "hromadné zvýšené startovné" a "hromadné startovné soztěže". Do pole napiš číslo a klinkni někam vedle. Číslo se automaticky přepíše do daného sloupce u všech kategorií, pokud tam již cena nebyla zapsaná v minulosti, tzn. pouze do prázdných buněk. Pokud už kategorie má startovné zapsané z minulosti, cena se nezmění, aby se zabránilo nechtěnému přepisování.<br>
      <b>Startovné</b> se počítá, pokud je uživatel přihlášen v I. termínu přihlášek. <b>Zvýšené startovné</b> se počítá, pokud je závodník přihlášen ve II. termínu přihlášek. Pokud cenu za II. termín neuvedeš, ale přesto bude závodník přihlášen ve II. termínu přihlášek, automaticky se mu počítá cena uvedená v I. termínu přihlášek. Pokud neuvedeš žádnou cenu za startovné, počítá se automaticky 0 Kč.<br>
      Ve správě kategorií v hlavní soutěži lze také zadat startovné za přihlášku do "hlavní kategorie soutěže". Sloupeček "startovné soutěže". Toto lze využít ve výjimečných případech, např. pokud nemáš v soutěži žádné etapy a chceš přihlášku zpoplatnit. 

      <h3>Vyhledávané kontroly</h3>
      Pokud rozklineš etapu a klikneš na tlačítko spravovat kategorie, tak zde můžeš pro zvolenou etapu vypsat v jednotlivých kategoriích na konci řádku, které kontroly kategorie vyhledává. Toto pole slouží pouze ke zobrazení výčtu vyhledávaných kontrol závodníkům ve výsledcích a nepodílí se na validaci dat apod. 


    </p>
    `
  },


  "event-starting-fee": {
    title: "Startovné",
    content: `
    <p>
     Startovné je možné definovat ve správě kategorií v nastavení soutěže (modré tlačítko spravovat kategorie hromadně, popř. spravovat kategorie). Jak na to, je popsáno v tématu <a href="#" data-topic="event-category"> správa kategorií</a>
    </p>`
  },







  "event-editors": {
    title: "Správci soutěže",
    content: `
    <p>
      V nastavení soutěže může <b>autor</b>, který soutěž vytvořil určit, kdo přesně může soutěž editovat. Lze vybrat několik účtů z těch, které v robisu jsou. Pokud je pole prázdné, editovat soutěž může autor, který soutěž vytvořil a všichni, kteří mají pravomoc organizátor a jsou ze stejného oddílu, jako je pořádající oddíl. Pokud vyplníš pole správci této soutěže, automaticky se tím uzavře přístup organizátorům z oddílu. Editovat soutěž pak mají oprávnění autor soutěže a účty, které jsou uvedené v poli "správci této soutěže". Pokud chceš omezit pravomoce editace pouze na sebe, vyber jako správce sebe.  
    </p>`
  },

  "event-canceled": {
    title: "Zrušení (odvolání) soutěže",
    content: `
    <p>
      V případě nenadálé situace je možné, že chceš v robisu upozornit závodníky, že došlo ke zrušení soutěže, ale nechceš soutěž z robisu smazat. V tomto případě zvol v dalším nastavění soutěže "nastav zrušení soutěže". V tomto případě se zachovají všechny data v soutěži, ale nebude závodníkům účtováno startovné, ubytování, strava a služby.<br>
      <a href="${BaseUrl.value}static/help/event_canceled.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_canceled.png" alt="" width="500px">
      </a>
    </p>`
  },

  "event-start-choice": {
    title: "Volba startu",
    content: `
    <p>
      Tato funkce umožňuje závodníkům v přihláškách zvolit si dobu startu. <b>Možnosti: 0 - první startující, 1 - první skupina, 2 - druhá skupina, 3- třetí skupina, 4- poslední startující.</b> Informace o volbě závodníků se zobrazí v XLSX souboru a v některých z exportovaných CSV souborů, které slouží pro import dat do vyčítacích softwarů. Jinde se tato volba neprojeví a je tak na vůli organizátora, jestli ji využije. Pokud ji neplánuješ využít, nech tuto volbu vypnutou.  
      <br>
      <a href="${BaseUrl.value}static/help/event_start_choice.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_start_choice.png" alt="" width="900px">
      </a>
    </p>`
  },

  "event-map": {
    title: "Body na mapě",
    content: `
    <p>
      Na stránce soutěže (ne v nastavení soutěže) je mapa zobrazující body, jako např. místo shromaždiště, start, cíl, ubytování apod. Běžní uživatelé mohou kliknout na název bodu a tím spustit navigaci na google mapách. Uživatelé s pravomocí editovat soutěž (<a href="#" data-topic="event-permissions">viz pravomoce</a>) mohou bod vložit kliknutím na levé tlačítko myši na konkrétní bod v mapě. Spustí se dialogové okno, které vyzve uživatele k vložení popisu daného místa. Souřadnice se uloží automaticky. Ikona pro smazání je viditelná pouze pro správce  soutěže.
      <br>
      <a href="${BaseUrl.value}static/help/event_map.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_map.png" alt="" width="1100px">
      </a>
    </p>`
  },

  "event-entry-edit": {
    title: "Editace přihlášených z pohledu pořadatele",
    content: `
    <p>
      V nastavení soutěže lze ve druhém menu níže přes tlačítko "přihlásit" v sekci "správa přihlášených" přihlásit závodníky a to i po termínu přihlášek. Po kliknutí na tlačítku přihlásit se otevře dialogové okno, které je podobné tomu na stránce s přihlášením na soutěž. Ve vrchní části má správce soutěže možnost zvolit přihlášení závodníka z databáze nebo vytvořit jednorázovbou přihlášku. Přihlášením závodníka z databáze pomocí příjmení se předvyplní údaje a přihláška se spáruje s účtem závodníka. Ve druhém případě jednorázové přihlášky bez databáze se přihláška nespáruje.<br>
      Na rozdíl od plnohodnotné přihlášky správce soutěže nemusí vyplňovat všechny údaje a má k dispozici seznam všech kategorií nefiltrovaných podle ročníku narození a pohlaví. Tzn. může závodníkovi vybrat jakoukoli kategorii a nedodržet tak pravidla ROB. 
      <br>
      <a href="${BaseUrl.value}static/help/event_entry_edit.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_entry_edit.png" alt="" width="1400px">
      </a>
    </p>`
  },


  "event-food-acc": {
    title: "Ubytování a strava",
    content: `
    <p>
     Ve spodním menu může správce soutěže navolit ubytování a stravu. Tyto položky se nabídnou na objednání v přihlášce. Uložení ubytování a stravy proveď tlačítkem uložit vpravo dole. 
     <h3>Ubytování</h3>
     Cena za ubytování je na jeden den. Robis zajistí, aby se nepřekročila kapacita ubytování, pokud ji tam zadáte. Pokud pole zůstane nevyplněné, kapacita je neomezená. Datum u ubytování nelze po uložení z technických důvodů měnit. Pokud potřebujete změnit datum, musíte smazat celé ubytování a vyplnit jej znovu. To nelze, pokud jsou v něm závodníci již přihlášení. Můžete je odhlásit v editaci přihlášky v sekci "správa přihlášených". Pozor, na každý jeden den v datumu od, do se vytvoří jedna položka ubytování pro přihlášky. Tzn. pokud máte soutěž pá-ne 7.-9.6. (přespání pá-so a so-ne), datum uveďte pouze na dva dny, nejlogičtější bude 7.6.-8.6. vytvoří se tak dva dny ubytování. Lze uložit maximálně 4 typy ubytování.
     <h3>Strava</h3>
     Jedna tabulka je vždy na jeden den. Počet polí je předem daný, tzn. na oběd a večeři lze poskytnout pouze 4 varianty jídel. Pokud nějakou variantu nevypůníte, nebude nabízena. Vyplňujte tabulku odzhora dolů, zleva doprava. přes tlačítko "přidej další dny jídla" můžeš přidat další položky následující dny. Nezapomeň vyplnit datum. Pokud si přeješ nějaký den zrušit, stačí smazat všechny data v polích pro tento den a uložit. 
      <br>
      <a href="${BaseUrl.value}static/help/event_food_acc.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_food_acc.png" alt="" width="1100px">
      </a>
    </p>`
  },


  "event-services": {
    title: "Služby",
    content: `
    <p>
      V natsavení soutěže lze přidat služby, které si závodníci mohou objednat v přihlášce. 
      <h3>Půjčení čipu</h3>
      Pokud pole neobsahuje cenu (číslo), půjčení čipu nebude vůbec umožněno. Pokud bude hodnota půjčení 0, půjčení čipu je zdarma. Pozor, někteří závodníci nevlastní čip, proto jej v přihlášce nemohou vyplnit. Avšak přihláška vyžaduje mít buď číslo čipu vyplněné, nebo mít zaškrtnuté půjčení čipu. Je vhodné půjčení čipu závodníkům umožnit. Nutnost mít v soutěži čip lze zrušit v nastavení soutěže. 
    </p>`
  },

  "event-workers": {
    title: "Činovníci a organizátoři soutěže",
    content: `
    <p>
      <a href="${BaseUrl.value}static/help/event_workers.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_workers.png" alt="" width="900px">
      </a>
      <br>
      Stránka soutěže -> nastavení soutěže -> etapa. V etapách můžeš evidovat organizátory a činovníky, kteří se na organizaci etapy podíleli. V seznamu lidí je uvedená i aktuální třída rozhodčího. Vyplnění je důležité zejména pro <b>započtení průměrů do (nejen) národního žebříčku</b>. Dále se těmto lidem zapíše praxe na jejich profil. Tato praxe je důležitá pro prodloužení tříd rozhodčího, nebo při žádosti o vyšší třídu.  
      <br>
      <a href="${BaseUrl.value}static/help/account_experience.png" target="_blank">
        <img src="${BaseUrl.value}static/help/account_experience.png" alt="" width="1300px">
      </a>
      <br>
    </p>`
  },

  "event-apikey": {
    title: "API klíč etapy",
    content: `
    <p>
      API klíč nalezneš v nastavení každé etapy. Tento klíč potřebují vyčítací softwary, aby je mohl robis při přijímání jejich online výsledků autorizovat. Bez api klíče nemůžeš do robisu takto data posílat, abychom zabránili nechtěnému přepisu dat nebo napadení databáze robisu. Více o možnosti posílat do robisu data nalezneš na stránce API v menu.
 Pozor, api klíč nejde resetovat. Proto buďte s jeho šířením opatrní. V případě nutnosti jej lze resetovat přes vývojáře robisu. Stačí napsat na email uvedený v zápatí.<br> 
      <a href="${BaseUrl.value}static/help/event_apikey.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_apikey.png" alt="" width="900px">
      </a>
    </p>`
  },
  
  "event-startlist-results": {
    title: "Nahrání startovek a výsledků",
    content: `
    <p>
      Kalendář -> soutěž -> nastavení soutěže -> etapa. Po výběru etapy v nastavení soutěže máš možnost nahrát startovku a výsledky pro etapu. Nahrání výsledků je důležité pro evidenci závodníků, počítání bodů do žebříčků, výkazy pro ČUS a NSA a samozřejmě závodníky samotné to také zajímá. 
      <h3>Startovní listina</h3>  
      Parametry a formáty najdeš v tématu <a href="#" data-topic="dev-startlist">startovka pro vývojáře</a>. Vyber startovka z menu a klikni na "nahrát startovku". Vlož soubor CSV, který je vygenerovaný z některých ze vyčítacích softwarů třetích stran. Robis sám neumí startovku vytvořit. Klikni na nahrát. Pokud se ukáže chybová hláška, zkus ji vyřešit. V případě potíží nás můžeš kontaktovat na emailu dole v zápatí. Pokud se soubor úspěšně nahraje, klikni na další.   
      <br> 
      <a href="${BaseUrl.value}static/help/event_startlist_1.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_startlist_1.png" alt="" width="900px">
      </a><br>
      V dalším kroku se dostaneš ke kontrole dat. Záznamy mají zabarvené pořadí podle toho, jak se data spárovala s daty v robisu:
      <li class="bg-green-lighten-2">Zelená - záznam našel shodu s přihláškou závodníka na soutěž, vše je ok</li>
      <li class="bg-orange-lighten-2">Oranžová - záznam nenašel shodu s daty v robisu. Závodník se buď nespároval, nebo jeho přihláška na etapu vůbec neexistuje. Přihláška se pro závodníka automaticky vytvoří.</li>
      <li class="bg-deep-orange-lighten-2">Červená - závodník je v robisu na etapu přihlášený, ale v nahraném souboru o něm není žádný záznam, nebo se přihláškou nespároval.</li>
      Záznamy se s daty v robisu párují v pořadí: INDEX, Příjmení a Jméno. Po kontrole klikni na uložit. Robis zvaliduje záznamy. Pokud si s errorem nevíš rady, přejdi na konec tohoto tématu, kde jsou vysvětlené. <b>Uložením se přepíše původní startovka</b>.

      <br> 
      <a href="${BaseUrl.value}static/help/event_startlist_2.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_startlist_2.png" alt="" width="800px">
      </a>

      <h3>Výsledková listina</h3> 
      Parametry a formáty najdeš v tématu <a href="#" data-topic="dev-results">výsledky pro vývojáře</a>. Vyber výsledky z menu a klikni na "nahrát výsledky". Zvol typ souboru na základě přípony, kterou soubor má. Soubor získáš vygenerováním z některých ze vyčítacích softwarů třetích stran. Klikni na nahrát. Pokud se ukáže chybová hláška, zkus ji vyřešit. V případě potíží nás můžeš kontaktovat na emailu dole v zápatí. Pokud se soubor úspěšně nahraje, klikni na další.
      <br>
      Ve druhém kroku můžeš zkontrolovat, popř. doplnit výčet kontrol, které každá kategorie vyhledává. Ne každý formát umí nahrávat výčet kontrol pro kategorie. Např. pro XML to specifikované nemáme. OCM CSV to umí. tento výčet slouží pouze ke zobrazení vyhledávaných kontrol v kategorii. Není to potřeba k validaci dat. Ale je to přínosné pro uživatele, který si výsledky prohlíží. Po kontrole klikni na další. 
      <a href="${BaseUrl.value}static/help/event_results_1.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_results_1.png" alt="" width="800px">
      </a><br>
      V posledním kroku se dostaneš ke kontrole dat. Záznamy mají zabarvené pořadí podle toho, jak se data spárovala s daty v robisu:
      <li class="bg-green-lighten-2">Zelená - záznam našel shodu s přihláškou závodníka na soutěž, vše je ok</li>
      <li class="bg-orange-lighten-2">Oranžová - záznam nenašel shodu s daty v robisu. Závodník se buď nespároval, nebo jeho přihláška na etapu vůbec neexistuje. Přihláška se pro závodníka automaticky vytvoří.</li>
      <li class="bg-deep-orange-lighten-2">Červená - závodník je v robisu na etapu přihlášený, ale v nahraném souboru o něm není žádný záznam, nebo se přihláškou nespároval.</li>
      Záznamy se s daty v robisu párují v pořadí: INDEX, Příjmení a Jméno. Záznamy se nedají editovat, pouze zobrazit jejich detaily a nebo smazat. Po kontrole klikni na uložit. Robis zvaliduje záznamy. Pokud si s errorem nevíš rady, přejdi na konec tohoto tématu, kde jsou vysvětlené. <b>Uložením se přepíší původní výsledky</b>.
      <br>
      <a href="${BaseUrl.value}static/help/event_results_2.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_results_2.png" alt="" width="800px">
      </a><br>


      <h3 style="color:red">Výpis chybových hlášek:</h3> 
      <li><code>Chybný formát relativního času 'xy' pro záznam: xy. Povolený formát je mm nebo mm:ss.</code> - Absolutní čas znamená denní čas. Např. že závodník startuje v 10:35:00. Relativní čas je v MM:ss od startu 00, např. 85:00. Start 00 se získá z nastavení soutěže. V souboru může být k dispozici pouze jeden z obou časů.</li>
      <li><code>Musíš pro závod v jeho nastavení uložit start 00</code></li>
      <li><code>Kategorie 'xy' nenalezena pro záznam: xy</code> - při nahrávání dat je potřeba mít v etapě v robisu vytvořené všechny kategorie, které posíláš v souboru. Tato chyba znamená, že když např. nebyla nalezena kategorie H pro daného závodníka, není v etapě založená kategorie s názvem H. záznam se nemůže uložit.</li>
      <li><code>Chybí Jméno pro záznam: xy </code> - Závodník je ukládán bez Křestního jména</li>
      <li><code>Chybí Příjmení pro záznam: xy</code> - Závodník je ukládán bez Příjmení</li>
      <li><code>Chybí Kategorie pro záznam: xy</code> - Závodník nemá v záznamu uvedenou kategorii, ve které závodí</li>
      <li><code>Ukládaná kategorie 'xy' nebyla nalezena</code> - V seznamu kategorií s výčtem kontrol se ukládá kategorie, která v etapě v robisu neexistuje. Kategorie musejí mít stejný název, jinak se nespárují a musejí být v etapě předem vytvořené.</li>
      <li><code>"Kategorie 'xy' nenalezena v hlavní soutěži pro: xy. Musíš ji vytvořit")</code> - Nelze vytvořit přihlášku, protože ukládaná kategorie neexistuje v hlavní souteži. Musíš ji v ní nejdříve vytvořit.
      <li><code>Chybí čas běhu nebo umístění pro xy, zatímco status výsledků je OK nebo nevyplněný.</code> - Pokud máš status výsledku závodníka jako OK, pak nelze uložit jeho čas jako 0:00 nebo prázdný, protože takový výsledek by nedával smysl.</li>
      <li><code>"SI číslo: xy", "Startovní číslo: xy", "Index : xy" "má duplicitní údaje"</code> - tento údaj uvádíš i jinde v souboru, což není možné, protože tyto údaje musejí být pro každého závodníka unikátní. Např. ukládáš dva závodníky se stejným číslem čipu.</li>
      <li><code>Neplatný formát času běhu: xy pro xy</code> - čas běhu musí být ve formátu MM:ss. Např. 98:15</li>
      <li><code>Neplatný formát mezičasu: xy pro xy</code> - formát mezičasů musí být m:ss. nebo MM:ss Např. 5:15</li>
      <li><code>Neplatná hodnota v umístění: xy pro xy</code> - do proměnné place se neukládá číslo (umístění), ani jeden z možných statusů: OK, MP, DNS, DNF, DSQ, NR, OVT, UNF, ERR. V takovém případě se vypíše chyba.</li>
      <li><code>Neplatný status kontroly: xy pro kód xy u xy</code> - Možnosti jsou pouze: OK, MP, DP, AP </li>
      <li><code>Neplatný typ kontroly: xy pro kód xy u xy</code> - Možnosti jsou pouze: CONTROL, FINISH, BEACON, SEPARATOR</li>
      <li><code>Chybí povinné údaje Příjmení, Jméno nebo název kategorie pro xy SI: xy</code> - jeden z údajů se v souboru neposílá a záznam nemůže být uložen</li>
      <li><code>V kategorii 'xy' čísla umístění xy obsahují nespojitelnou posloupnost. Chyba na pozici xy</code> - Nelze ukládat výsledky s umístěním závodníků, které mají vynechaná čísla. Např. 1. 2. 3. 6. 7. atd. Musí se jednat o postupku. Pokud je nějaké číslo vynechané, zobrazí se chyba. Povoleno je pouze umístění na stejném místě. Např. 1. 2. 2. 4. 5.</li>
    </p>
    `
  },

  "event-permissions": {
    title: "Pravomoce pro editaci soutěže",
    content: `
    <p>
      Člen oddílu s oprávněním organizátor má právo vytvořit soutěž pod hlavičkou svého oddílu. Po vytvoření mají právo soutěž spravovat:
      <li>Autor, který soutěž vytvořil,</li>
      <li>Organizátoři ze stejného oddílu</li>
      Autor má možnost v nastavení soutěže zvolit správce soutěže. Viz <a href="#" data-topic="event-editors">správci soutěže</a>. Pokud je toto pole v nastavení soutěže vyplněné, pravomoc organizátor pro soutěž nehraje roli a tytot účty přestanou mít možnost soutěž editovat. V tomto případě může soutěž spravovat:
      <li>Autor, který soutěž vytvořil,</li>
      <li>Správci soutěže uvedení v nastavení soutěže</li>
      Další možnost, jak posílat data o soutěži (startovky, výsledky) je přes api klíč dostupný v nastavení etapy. Viz <a href="#" data-topic="event-apikey">API klíč</a>. Pomocí api klíče se nedá dostat do nastavení soutěže ve webovém rozhraní a měnit údaje o soutěži. Pozor, api klíč nejde resetovat. Proto buďte s jeho šířením opatrní. V případě nutnosti jej lze resetovat přes vývojáře robisu. Stačí napsat na email uvedený v zápatí. 
    </p>`
  },










  "event-entry": {
    title: "Přihlášení na soutěž",
    content: `
      <a href="${BaseUrl.value}static/help/entry_button.jpg" target="_blank">
        <img src="${BaseUrl.value}static/help/entry_button.jpg" alt="pole pro vyplnění ID účtu" width="800px">
      </a><br>
      <p>
        Po vytvoření přihlášky se uloží termín přihlášky, který se nemění ani po editaci přihlášky a to i v případě, pokud už běží jiný termín přihlášek. Pokud si ale uživatel přihlášku smaže, termín se mu smaže také a pokud jsou otevřené přihlášky už v termínu druhém, tak se závodníkovi zapíše tento druhý termín, pokud si přihlášku založí znovu. To může mít za následek např. zvýšené startovné. 
      </p>

      <h3>Neregistrovaný uživatel</h3>
      <p>Po rozkliknutí soutěže v kalendáři klikni na přihlásit se a vyplň údaje. Přihlášku po odeslání nemůžeš editovat, proto si prosím před odesláním přihlášky pečlivě zkontroluj vyplněné údaje. Změnit údaje může jen organizátor události.</p>
      
      <h3>Registrovaný uživatel</h3>
      <p>Po rozkliknutí soutěže v kalendáři klikni na přihlásit se. Osobní údaje se ti automaticky předvyplní podle nastavení v účtu. Přihlášku můžeš editovat i po odeslání.</p>
      
      <h3>Přihlašování členů rodiny</h3>
      <p>Závodník (i předseda oddílu) si může ve svém nastavení vyplnit správce mých přihlášek, který jej může na soutěže přihlašovat. Oprávněný závodník má poté právo přihlašovat tento účet na soutěže a poté i editovat jeho údaje v soutěži do vypršení termínu přihlášek.<br>
        <a href="${BaseUrl.value}static/help/assist_user.png" target="_blank">
          <img src="${BaseUrl.value}static/help/assist_user.png" alt="pole pro vyplnění ID účtu" width="400px">
        </a>
      </p>
    <h3>Přihlášky členů oddílu na soutěž pro správce oddílu</h3>
      <p>Manipulace s přihláškami je pro správce oddílu možná v přihlašování na soutěž. V rozevíracím seznamu se nejdříve řadí přihláška přihlášeného uživatele, poté přihlášky těch účtů, které má dovolené přihlášený uživatel upravovat, až poté se zobrazí seznam členů oddílu vzestupně podle příjmení.</p>
    <h3>Na co je "hlavní kategorie"?</h3>
    <p>V případě přihlášek např. na soustředění nemusí být vypsána žádná etapa. V tomto případě by něšlo vybrat kategorii, ve které chce být závodník zapsaný, popř. by nešly vybrat poplatky spojené s toutto kategorií. Proto byl zaveden výběr "hlavní kategorie" aby byl přihlášený závodník vždy v nějaké kategorii veden. Dále se pomocí této kategorie filtrují závodníci na stránce soutěže v sekci "přihlášení závodníci". </p>

    <h3>Ubytování a strava</h3>
      <p>Pokud to pořadatel umožňuje, společně s přihláškou na závod lze přihlásit ubytování nebo stravu. V případě ubytování se automaticky vyplňuje typ ubytování, který uživatel v některém z polí vybere. Předejde se tím např. změně ubytování v polovině trvání soutěže a zmatkům s ubytováním. Automaticky se vyberou všechny dny ubytování, ale lze je jednotlivě zrušit.</p>

    `
  },

  "event-bulk-entry": {
    title: "Hromadné přihlašování",
    content: `
    <p>
      <a href="${BaseUrl.value}static/help/event_bulk_entry_1.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_bulk_entry_1.png" alt="" width="600px">
      </a>
      <br>
      Na stránce přihlašování na soutěž, kde vytváříš nové přihlášky je zelené tlačítko "hromadná přihláška". Toto tlačítko je dostupné pouze tehdy, když máš v seznamu pro vytvoření přihlášky možnost přihlásit více jak jednoho člověka. Kliknutím na tlačítko se ti otevře dialogové okno. Každý řádek patří jednomu závodníkovi. Kromě cen tam najdeš všechny možnosti přihlášky jako na na stránce pro jednotlivé přihlašování. <br>
      Jakou objednáváš stravu poznáš tak, že najedeš myší na checkbox, kde se ti zobrazí, jestli je dané jídlo snídaně, oběd nebo večeře. V režimu select boxu je to nadepsané v názvu pole. Pozor, dialogové okno má stránkování kvůli optimalizaci načítání. Na další stránku přejdeš dole uprostřed. Stránkování je po 20 lidech. V horní části okna je možnost vyhledávání podle jména závodníka. Filtr se aktivuje po zadání nejméně 3 písmen. Pište s diakrtikou!<br>
      Odesílají se ti závodníci, kteří mají <b>zaškrtnutý checkbox</b> vlevo od jména!!! Pokud není zaškrtnutý nikdo, žádná data se neodesílají. Po úspěšném přihlášení lze závodníky editovat pouze jednotlivě na stránce přihlášení. 
      <br>
      <a href="${BaseUrl.value}static/help/event_bulk_entry_2.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_bulk_entry_2.png" alt="" width="1200px">
      </a><br>
            <a href="${BaseUrl.value}static/help/event_bulk_entry_3.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_bulk_entry_3.png" alt="" width="800px">
      </a>

      <br>
    </p>`
  },

  "event-organizers-entry": {
    title: "Přihlašování organizátorů",
    content: `
    <p>
      Od verze 0.4.5 má správce soutěže možnost vytvořit kategorii ORG v nastavení soutěže. Tato kategorie se zobrazuje všem ze stejného oddílu, jako je pořádající oddíl. 
      <br>
      <a href="${BaseUrl.value}static/help/event_organizers_entry.png" target="_blank">
        <img src="${BaseUrl.value}static/help/event_organizers_entry.png" alt="" width="120px">
      </a>
      <br>
      Myšlenkou je začlenit organizátory do přihlášek kvůli přihlašování ubytování a stravy na závodech nebo jen kvůli přehledu. Kategorie ORG se sama automaticky s vytvořením etapy nezaloží. Jak spravovat kategorie najdete v tématu <a href="#" data-topic="event-category">Správa kategorií</a>.
    </p>`
  },



  "event-automodule": {
    title: "Automodul",
    content: `
        <p>Automodul slouží k rozdělování lidí do dopravy. Minimální počet míst je 1, maximální počet míst je 50. Dobrá praxe pro nejsnadnější kooperaci je taková, že všichni lidé s možností vzít auto jej založí. Zbytek se do aut přihlásí. Pokud je aut nedostatek nebo přebytek, je potřeba, aby se účastníci domluvili a počet vyvážili. Automodul mohou správci soutěže pro soutěž vypnout, a to v "dalším nastavení soutěže".</p>
        <h3>Funkce automodulu:</h3>
        <p>První pole je pole řidiče. Na něj se vypíše vyúčtování cestovného, neboť se předpokládá, že řidič je i vlastník auta a kupující pohonných hmot. Editovat dopravní prostředek a řidiče může pouze řidič sám a správce oddílu. Pokud snížíte počet míst v automodulu, smažou se i přihlášení cestujících a to odzadu. Nelze smazat místa při přítomnosti jednosměrných cest. Příjmení a Jméno vkládejte v tomto pořadí a ve správném formátu ať se data správně spárují. Pokud jsi přihlášen, pole se vyplní automaticky. Pokud oddíl využívá vyúčtování cestovného, doplňte potřebné údaje v editaci automodulu a v nastavení uživatele (jen řidič). Cestujícímu lze přiřadit cestu pouze jedním směrem! Stačí v režimu editace sedadla kliknout na šipečky označující obousměrnou cestu a ta se změní pouze na jeden nebo druhý směr. Toto se projeví i v cesťáku.<br>
        Stažení cesťáku je možné v režimu editace celého auta. v této editaci se také vyplňují všechny potřebné informace z cesty pro doplňění do cesťáku.
        <h3>Pravomoce:</h3>
        <li>Autor a řidič auta může editovat všechny místa ve svém autě a auto samotné.</li>
        <li>Správce oddílu může editovat všechny auta, které jsou z jeho oddílu.</li>
        <li>Konkrétní místo v autě může editovat člověk, který na místě sedí, autor, který provedl zápis, řidič a autor, který vytvořil dané auto.</li>
      
          </p>
`
  },

  "event-livelox": {
    title: "Livelox",
    content: `
        <h3>Závodník</h3>
        <p>Zaregistruj se na <a href="https://www.livelox.com/" target="_blank">livelox.com</a> a připoj si ve svém účtu aplikaci, ze které se budou nahrávat do liveloxu data nahraná z hodinek. Zatrhni všechny políčka při propojování se službou. Vyber jen jednu aplikaci pro nahrávání, aby se záznamy neduplikovaly. Nastav v účtu soukromí na veřejné.<br>
        Postupy se ti budou k soutěžím nahrávat automaticky po vyčtení hodinek.</p>
        <h3>Pořadatel</h3>
        <p>Zaregistruj se na <a href="https://www.livelox.com/" target="_blank">livelox.com</a>. V menu klikni na spravovat a klikni na přidat akci. Vyplň pole podle potřeby. Dbej zvýšené pozornosti na sekce <b>Čas zveřejnění a Správci</b>, ať se k závodníkům mapa nedostane dříve, než vůbec odjedou na start. <b>Vyhledatelnost nechej odškrtnutou</b>, aby se akce nezobrazovala v kalendáři akcí na liveloxu. Ve správě události vyber editovat mapu. Mapu můžeš vložit nahráním ocad, kmz nebo obrázkového souboru. Doporučuji vložit mapu již se zakreslenými kontrolami. Můžeš vložit i prázdnou mapu a tratě importovat z ocadu ve správě události, ale je to složitější a má to mouchy. V dalším kroku, pokud máš mapu georeferencovanou, zkontroluj, jestli sedí a kdyžtak uprav. Pokud vkládáš obrázek, musíš mapu georeferencovat, aby seděla se skutečností. Doporučuji vytvořit alespoň 3 body. Ulož.</p>
        <p>Nyní je potřeba importovat účastníky, aby livelox dokázal spárovat uživatele s kategorií, jinak se budou muset uživatelé přidávat ručně. Ve správě události v okně výsledky vyber importovat startovku/výsledky. Text záleží na tom, jestli už událost porběhla, nebo ne. Nyní je potřeba nahrát soubor s databází závodníků. Přejdi v ROBISu do příslušné soutěže. Zkontroluj, jestli máš nastavený start 00. Pokud ne, nastav ho, jinak to livelox nevezme. Stáhni si do PC "Data pro livelox". Stažený soubor xml nahraj do liveloxu. Nahrát ho musíš 2 krát, pokud si předtím ručně nevytvořil v události kategorie, což ani nedoporučuji. Poprvé se kategorie vytvoří a po druhém nahrání se livelox kategorie spárují s kategoriemi v xml souboru. Hotovo, uživatelé se po vyčtení jejich hodinek sami do postupů nahrají.</p>
        <p>Nezapomeň vložit url adresu na stránku soutěže v robisu, aby se tam uživatelé dostali. Url by měla vypadat nějak takto: https://www.livelox.com/Events/Show/117784/MCR35MHz.</p>
    `
  },


  "dev-info": {
    title: "Obecné informace",
    content: `
        <h3>Obecné informace</h3>
        <p> Robis využívá CSV, XML a JSON. Formát a adresy naleznete v menu pod Exporty. XML strukturu vymyslela IOF a <a href="https://github.com/international-orienteering-federation/datastandard-v3" target="_blank">celá dokumentace</a> je k dispozici na <a href="https://orienteering.sport/iof/it/data-standard-3-0/" target="_blank">tomto rozcestí</a>. Výhodou je, že data mohou být poházená v celém souboru zcela náhodně (nezáleží na pořadí hodnot), protože každá hodnota je opatřena jasným identifikátorem, který má definováno, co je hodnota zač. Malou nevýhodou je trochu složitější struktura xml souboru. JSON formát je zjednodušený XML, ve kterém se posílají sice totožná data, ale jeho syntaxe není tak složitá, tím pádem celkový počet přenesených dat je nižší. Je tak vhodný například pro přenos online výsledků. Na standardizaci JSON formátu pro ARDF pracujeme.</p>
      
    `
  },
  "dev-sidroid": {
    title: "SI Droid Event",
    content: `
      <h3>SI Driod Event</h3>
        <p>Export záznamů do csv pro <a href="https://play.google.com/store/apps/details?id=se.joja.sidroid.event.full" target="_blank">SI Droid Event</a> je možný jako souhrn všech účtů v robisu (na stránce export) nebo v sekci startovek v nastavení závodu v rozevíracím seznamu export, pouze pro konkrétní závod. Tam je přidána i kategorie, ve ketré závodník v daném závodě běží. Protože hodnty čísla čipu a jména jsou pro SI Droid povinné, záznamy s těmito chybějícími údaji se neexportují.</p>

      
    `
  },
  "event-xls": {
    title: "Seznam závodníků",
    content: `
     <h3>Seznam závodníků (pouze export)</h3>
        <!--<ul>
            XML vytvořeno pomocí této <a href="https://github.com/international-orienteering-federation/datastandard-v3/blob/master/examples/EntryList1.xml" target="_blank">dokumentace</a>. Ve vzorovém souboru jsou uvedeny všechny hodnoty, které ROBis v seznamu generuje -<a href="${BaseUrl.value}/static/seznam_prihlasenych.xml" target="_blank">Vzorový seznam závodníků.xml</a><br>
            Poznámky:
            <li>Pokud závodník nemá uvedený čip, chce jej půjčit.</li>
            <li>Neexistuje mezinárodní definice indexu, proto je index uveden v elementu PERSON <code>&lt;ID type="INDEX"&gt;GBM0000&lt;/ID&gt;</code></li>
            <li>Zkratka oddílu je uvedena v elementu ORGANISATION <code>&lt;ShortName&gt;GBM&lt;/ShortName&gt;</code></li>
            <li>Požadavek na start není v IOF definici uveden. Existuje v elementu START <code>&lt;StartRequest&gt;2&lt;/StartRequest&gt;</code> Nabývá hodnot: 0 - první startující, 1 - první skupina, 2 - druhá skupina, 3 - třetí skupina, 4 - poslední startující.</li>-->
        <ul>
          XLSX - slouží pro oddíly a pořadatele pro souhrn informací přihlášených závodnků<br>
          Poznámky:
          <li>Autoriz. = autorizovaný závodník. Je to závodník, který se přihlásil na závod pomocí svého účtu v robisu a jeho údaje jsou tedy ověřené. Např Jméno, příjmení, index apod.</li>
          <li>Startovní skupina - Pokud pořadatel v nastavení soutěže zvolí možnost přihlášek do start. skupin, pak se v přehledu závodů zobrazí takto: 0 - první startující, 1 - první skupina, 2 - druhá skupina, 3- třetí skupina, 4- poslední startující.</li>
        </ul>      
    `
  },
  "dev-startlist": {
    title: "Startovka",
    content: `
              <h3>Startovka (export i import)</h3>
        <ul>
          Poznámky:
          <li>Pokud potřebujete v CSV formátu některý údaj vynechat, nevypisujte mezi ; a ; vůbec nic. Např. 3;Novák;Petr;M20;;11:05:30;GBM8105;OK123;;2045258</li>
          <li>Absolutní čas startu je podporován buď jako <code>hh:mm:ss</code> a nebo jako <code>yyyy-mm-ddThh:mm:ss</code>. S tím, že pokud bude vyplněný pouze první formát, tak se datum do databáze uloží z datumu konání závodu. Může být vyplněné pouze jedno ze startů.</li> 
          <li>Uložení volačky robis nepodporuje</li>
          <li>Stát - podporujeme pouze formát ISO 3166-1 alpha-3, což znamená třípísmenný kód zemí. Např. CZE.</li>
        </ul>
        <ul>
            <li>Import startovky - <code>Startovní číslo;Příjmení;Jméno;kategorie;relativní čas startu;absolutní čas startu;index;volačka;stát;číslo čipu.</code><br>
            Např. 3;Novák;Petr;M20;65:30;11:05:30;GBM8105;OK123;CZE;2045258</li>
            
        </ul>
            <!--Vytvořeno pomocí této <a href="https://github.com/international-orienteering-federation/datastandard-v3/blob/master/examples/StartList1.xml" target="_blank">dokumentace</a>.<br>
            Ve vzorovém souboru jsou uvedeny všechny hodnoty, které ROBis v seznamu generuje - <a :href="${BaseUrl.value}/static/startlist.xml" target="_blank">Vzorová startovka.xml</a>
            <li>Pozor, kategorie je nadřazený element pro všechny přihlášené v dané kategorii. Tzn. kategorie závodníka není uvedena u jeho výpisu dat, ale je uvedená pouze jako element, který obsahuje závodníky, kteří do této kategorie spadají.</li><br>
            Povinné údaje pro import startovky do robisu (dodržte strukturu xml souboru viz vzor):
            <li><code>&lt;ID type="ROBIS"&gt;*ID*&lt;/ID&gt;</code> (ID soutěže je například v url adrese) (Nutné vyplňit pouze, pokud startovku NENAHRÁVÁM přes web)</li>
            <li><code>&lt;Time&gt;xx:xx:xx&lt;/Time&gt;</code> (Start 00, pokud se liší od startu v robisu)</li>
            <li><code>&lt;ID type="INDEX"&gt;GBM5030&lt;/ID&gt;</code> (Index závodníka)</li>
            <li><code>&lt;StartTime&gt;xx:xx:xx&lt;/StartTime&gt;</code> (Start závodníka)</li>

      -->

    `
  },
  "dev-results": {
    title: "Výsledky",
    content: `
              <h3>Výsledky (export i import)</h3>
        <p>
          Robis podporuje hned několik typů importů a exportů v závislosti na typu souboru a zdrojovém softwaru. Kódování UTF-8, není-li uvedeno jinak.<br>
          Formáty pro import a jejich struktura:
        </p>       
                          
        <ul>
            <li><b>CSV:</b><br>
              Jedná se o vylepšený OCM CSV soubor. Formát nepočítá s týmovým hodnocením, ani štafetami. Naopak počítá s daty, která specifikují některé věci a ty se potom mohou lépe a přesněji zobrazit ve výsledkovém servisu. CSV soubor rozlišuje tři různé řádky: <b>Hlavičku závodu, Hlavičku kategorie, Výsledek závodníka</b>. Na pořadí řádků nezáleží. Údaje, které robis nezpracovává jsou označené <b>*</b>. Pokud je nechceš odesílat, pošli v csv prázdnou hodnotu: ;;<br>
              <code>*RH (race header); Race name; Start datetime 00; Race type; Race level; Race band; Race time limit </code><br>
              <code>CH (category header); Category name*; Category race type*; Limit*; Band*; Length*; Climb*; Control point string (např. 1, F2, 3, M)</code><br>
              <code>R (result header); Category name; Place; Result status; Last name; First name; Start number; Index; Club name*; Run time; Number of control points found; Punches (viz níže)</code><br>
              <code>Alias (např. F1, nebo kód), Split time (MM:ss), Punch status, Control status</code>. Ražení je odděleno #<br>
              Příklad řádků:<br>
              <code>RH;City Marathon;2024-12-01T08:30:00;CLASSIC;NATIONAL;M2;240<br>
                CH;Men's Elite;ORIENTEERING;120;M2;10.5;500;1 2 3 4 5 6 7 8<br>
                R;Men's Elite;1;OK;Novák;Petr;101;CZE123;Running Club;52:27;9;1,05:30,OK#2,5:10,OK#3,8:12,OK#4,6:15,OK#5,4:40,OK#6,2:50,OK,SEPARATOR#7,7:10,OK#8,12:20,OK,BEACON#F,0:20,OK,FINISH<br>
              </code><br>
              Hodnota v proměnných:
              <table class="result_csv_table">
                <thead>
                  <tr>
                    <th>Race type*</th>
                    <th>Race level*</th>
                    <th>Race band*</th>
                    <th>Result status</th>
                    <th>Punch status</th>
                    <th>Control type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      CLASSIC<br>SPRINT<br>FOXORING<br>LONG
                    </td>
                    <td>
                      INTERNATIONAL<br>NATIONAL - I. stupeň<br>REGIONAL - II. stupeň<br>DISTRICT - III. stupeň<br>PRACTICE<br>OTHER
                    </td>
                    <td>
                      M2<br>M80<br>COMBINED
                    </td>
                    <td>
                      OK - valid<br>MP - chyba ražení<br>DNS - nestartoval<br>DNF - nedokončil<br>DSQ - diskvalifikován<br>
                      NR - nehodnocen (např. méně něž 2 kontroly na klasice)<br>OVT - překročil limit<br>UNF - Neoficiální
                    </td>
                    <td>
                      OK<br>MP - chyba ražení (maják není poslední kontrolou)<br>DP - duplikované ražení<br>
                      AD - kontrola navíc
                    </td>
                    <td>
                      CONTROL<br>FINISH<br>BEACON<br>SEPARATOR
                    </td>
                  </tr>
                </tbody>
              </table> 
              Detailnější informace na <a href="https://github.com/kolskypavel/ARDF-Manager_mobile/wiki/Data-formats#4-results-export" target="_blank">githubu</a>.           
            </li>
            <li><b>CSV OCM:</b><br> 
              Robis zpracovává 2 řádky, které se odlišují druhým parametrem. Nabývá hodnot H nebo "" (prázdná hodnota). H - definice vyhledávaných kontrol pro kategorii. "" - výsledek závodníka. Výpis kontrol nebo mezičasů je oddělen čárkou ",". Odlišení jednotlivých kontrol nebo mezičasu je pomocí "#". Typ (kontrol) nabývá hodnot - 0 (standardní), 1 (maják), 2 (divácká). Status (kontroly) nabývá hodnot 0 (OK), 1 (špatná), 2 (neznámá). Více v <a href="${BaseUrl.value}static/OCM_dokumentace_vysledky.jpg" target="_blank">dokumentaci OCM</a>. Přijímaná struktura definice kontrol a výsledku závodníka:<br>
              <code>Název kategorie;H;Limit;Pásmo;Relativní start;Kontroly: Typ,Kód,Alias#</code><br>
              <code>Název kategorie;;Umístění;Startovní číslo;Příjmení;Jméno;Čas běhu;Počet kontrol;Mezičasy: Kód,Mezičas,Alias,Typ,Status#;Země;Číslo čipu;Zkratka oddílu;Index</code>
            </li>
            <li><b>XML IOF 3.0</b><br>
              Používáme standardní formát podle IOF 3.0. Ten však nepočítá s některými daty, které při importu využíváme. V elementu ControlCode využíváme status pro rozlišení mezi typem kontrol a může nabývat hodnot: <code>Beacon</code> (maják) a <code>Separator</code> (divácká). Pokud status není uveden, je to normální kontrola. Počet kontrol se počítá na základě mezičasů, které nemají Missing nebo Additional. Kódování je podporováno jak UTF-8, tak windows-1250. Kódování je nutné uvést na prvním řádku, např. <code>&lt;?xml version="1.0" encoding="utf-8"?&gt;</code>. První řádek není v náhledu vzorového souboru vidět. <a href="${BaseUrl.value}static/results.xml" target="_blank">Vzorový soubor výsledků.xml</a>.
            </li>
        </ul> 

    `
  },

  "dev-online-results": {
    title: "Online výsledky",
    content: `
              
        <p>
          ROBIS umožňuje nahrávání online výsledků softwarům třetích stran přes API (<a href="https://cs.wikipedia.org/wiki/API" target="_blank">co je API - wikipedia</a>). Na adresu API (endpointu) software pošle příslušná data a Race-Api-Key, kterým se autorizuje. Tento klíč najdeš v nastavení každé etapy viz téma <a href="#" data-topic="event-apikey">API klíč etapy</a>. Online výsledky můžeš na endpoint poslat buď jako soubor csv nebo json. Json je preferovaný, protože ho robis zpracuje rychleji. Více o API najdeš v MENU -> DALŠÍ -> API
        </p>       
                          
      `
  },

  
  "club-members": {
    title: "Správa členů",
    content: `
        <p>Pokud je uživatel veden jako předseda svého oddílu, může měnit pravomoce (organizátor a správce oddílu) a spravovat členy v oddíle. Správa členů se děje prostřednictvím tlačítek v posledním sloupci tabulky Správa:</p> <a href="${BaseUrl.value}static/help/predseda_edit.png" target="_blank"><img src="${BaseUrl.value}static/help/predseda_edit.png" alt="ikony akce pro editaci členů oddílu" width="100px"></a>
        <p>Přes jednotlivá tlačítka předseda může:</p>
          <li><b>měnit údaje členům oddílu</b> (jméno, příjmení, email, index, číslo SI, rok narození, pohlaví),</li> 
          <li><b>vyloučit uživatele z oddílu</b> (odebrat jim index a tím příslušnost k oddílu),</li> 
          <li><b>smazat účty</b>, které jsou mladší jak <b>14 dní</b>.</li>
          <br>
          <p>
          Přes editaci účtu může předseda nastavit (ale i sám uživatel v sekci nastavení) účet, který bude moci daného uživatele přes sebe v robisu na soutěže přihlašovat. Takto mohou rodiče přes svůj účet přihlašovat své děti.<br>
          <a href="${BaseUrl.value}static/help/club_person_assist_user_edit.png" target="_blank"><img src="${BaseUrl.value}static/help/club_person_assist_user_edit.png" alt="přidání uživatle pro povolení editace jiného účtu" width="600px"></a><br>
          Zrušení členství uživatele způsobí to, že uživateli bude ukončena role v oddílu a přiřadí se mu role nová, která nemá oddíl. Uživatel bude moci dál spravovat svůj účet a přihlašovat se na závody, ale bez členství v oddílu. V případě, že se chce uživatel připojit do oddílu, musí tuto akci provést předseda daného oddílu výběrem závodníků bez oddílu ze seznamu pod tlačítkem registrace. (bude doděláno)
        </p>
                
    `
  },

  "other-referee-coach": {
    title: "Evidence rozhodčích a trenérů",
    content: `
    <p>
      Evidovat třídy rozhodčích a trenérů může uživatel s pravomocí manažer. Evidence tříd slouží k celkovému přehledu a pro organizátory soutěží, aby věděli, kdo může dělat např. delegáta nebo rozhodčího na soutěži. Přehledy o třídách naleznete na stránce Členové v hlavním menu. Na profilu závodníka také naleznete historii záznamů jeho udělených tříd.<br>
      Manažer může třídy vytvářet a editovat jak na stránce Členové (poslední sloupeček Akce), tak na profilu konkrétního uživatele. Pokud se uživateli prodloužila platnost třídy, pak nevytvářej novou třídu, ale prodluž platnost té stávající. Pokud by dlošlo při vytváření nové třídy ke kolizi se stávající třídou (např. si závodník udělal I. třídu a předtím měl rok starou II. třídu), stará třída se mu automaticky ukončí na den předcházející platnosti nové třídy. 

      <br>
      <a href="${BaseUrl.value}static/help/other_referee_coach.png" target="_blank">
        <img src="${BaseUrl.value}static/help/other_referee_coach.png" alt="" width="1200px">
      </a>
    </p>`
  },
  
  "other-ranking": {
    title: "Národní a oblastní žebříčky",
    content: `
      <p>
        Národní a oblastní žebříčky najdete pod vysouvací záložkou v menu. Nastavení žebříčků (přidávání, odebírání závodů, určování započítávaných kategorií apod.) může uživatel s oprávněním <span style="color:rgb(255, 123, 0)">manažer</span>. Ten také nastavuje počet škrtnutých závodů v jednotlivých žebříčcích za sezónu (každý může nastavit zvlášť). Správce soutěže může na stránce etapy určit pořadatele přes tlačítko "činovníci a organizátoři", kteří dostanou do žebříčku průměr bodů z absolvovaných závodů jako kompenzaci, že si body nemohli v závodě kvůli pořádání vyběhnout (viz pravidla ROB). Průměry se započítávají až od data uskutečnění závodu (i když jsou v robisu zapsané už dříve). Škrtnutí nejhorších závodů se započte až po nahrání většího počtu závodů s výsledky, než je počet škrtaných výsledků celkem. Tzn. při 2 škrtaných závodech se toto pravidlo aplikuje, jakmile budou v robisu nahrané výsledky ze tří závodů v žebříčku. 
      </p>
      <h3>Národní žebříček</h3>
      <p>
        Do národního žebříčku se započítávájí soutěže I. stupně. Stupeň (úroveň) soutěže se musí zadat při vytváření soutěže a lze jej měnit v nastavení soutěže v části "další nastavení soutěže".
      </p>
      <h3>Oblastní žebříčky</h3>
      <p>
        Oblastní žebříčky jsou rozdělené podle oblasti, které odpovídají rozdělení indexů do těchto stejných oblastí. Závod je započítán do určité oblasti a tedy i oblastního žebříčku podle oblasti oddílu, který je pořadatelem dané soutěže. Do oblastních žebříčků jsou započítávány soutěže II. a III. stupně. Stupeň (úroveň) soutěže se musí zadat při vytváření soutěže a lze jej měnit v nastavení soutěže v části "další nastavení soutěže". Do jakéhokoli žebříčku se počítají všichni závodníci s registrací v ČR (AROB). Tzn. zahraniční závodníci se nepočítají.
      </p>
      <h3>Legenda:</h3>
      <li><b>Neuvedená hodnota</b> - závodník se závodu nezúčastnil.</li> 
      <li><b>Šedé zabarvení celého sloupce</b> - soutěž ještě nebyla uzavřena, výsledky a tedy i bodování se může změnit. Soutěž uzavírají její správci nebo se uzavře automaticky 30 dní od data začátku soutěže.</li> 
      <li><b>Přeškrtnutá hodnota</b> - závod je škrtnutý jako nejhorší výsledek. Počet škrtaných výsledků určuje uživatel s oprávněním manažer.</li>
      <li><b>Oranžová hodnota</b> - v daném závodě se pro závodníka započítává průměr z absolvovaných závodů z důvodu organizování v daném závodě. Lze započítat pouze tolik průměrů, kolik závodník absolvoval závodů.</li>
      <li><b>Bodová hodnota je 0</b> - závodník závod absolvoval, ale jeho výsledek je DNF, DNQ, OVT apod.</li>

      <br>
      <a href="${BaseUrl.value}static/help/other_ranking.png" target="_blank">
        <img src="${BaseUrl.value}static/help/other_ranking.png" alt="" width="1500px">
      </a>
    </p>`
  }




};

// Dynamicky načítá obsah podle zvoleného klíče
const getTopicTitle = computed(() => topicContent[selectedTopicKey.value]?.title || "Nápověda");
const getTopicContent = computed(() => topicContent[selectedTopicKey.value]?.content || "<p>Vyber si téma z menu</p>");

// Zvýrazenění vyhledávaného obsahu
const highlightedContent = computed(() => {
  if (!searchQuery.value) return getTopicContent.value; // Pokud není hledání, vrátí původní obsah

  const query = searchQuery.value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escapuje speciální znaky pro RegExp
  const regex = new RegExp(`(${query})`, "gi"); // Case-insensitive hledání
  return getTopicContent.value.replace(regex, '<mark>$1</mark>'); // Zvýrazní hledaný text
});



// Zjistí, zda jsme na mobilu
const isMobile = ref(window.innerWidth < 960);

const updateScreenSize = () => {
  isMobile.value = window.innerWidth < 960;
};


// Zajistí, že prokliky fungují správně
const handleContentLinks = (event) => {
  const topic = event.target.getAttribute("data-topic");
  if (topic) {
    event.preventDefault();
    selectTopic(topic);
  }
};

// Vyhledávač klíčových slov a zobrazení témat, kde se slova nacházejí
const searchQuery = ref("");

const filteredTopics = computed(() => {
  if (!searchQuery.value) return helpTopics.value;

  return helpTopics.value.map(category => {
    const matchingSubtopics = category.subtopics.filter(subtopic => {
      const topicKey = subtopic.key;
      const topic = topicContent[topicKey];
      return topic && (
        topic.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        topic.content.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    return matchingSubtopics.length > 0 ? { ...category, subtopics: matchingSubtopics } : null;
  }).filter(Boolean);
});



function showWidget() {
  const widget = document.querySelector('[script-id="5jCC8"]');
  if (widget) {
    widget.style.display = 'block'; // případně 'flex', pokud widget využívá flex
  }
}

function hideWidget() {
  const widget = document.querySelector('[script-id="5jCC8"]');
  if (widget) {
    widget.style.display = 'none';
  }
}



// Při načtení stránky zkontroluj hash a nastav téma:
onMounted(() => {
  showWidget();

  if (route.hash) {
    const key = route.hash.substring(1); // odebere "#"
    if (topicContent[key]) {
      selectTopic(key);
    }
  }
  // Změny velikosti okna
  window.addEventListener("resize", updateScreenSize);
  document.addEventListener("click", handleContentLinks);

  // spuštění chatbota
  // Zkontrolujeme, zda už skript neexistuje

  // Vytvoříme nový script s parametrem pro cache busting
  const scriptElement = document.createElement('script');
  scriptElement.src = `https://static.getbutton.io/widget/bundle.js?id=5jCC8&cb=${Date.now()}`;
  scriptElement.defer = true;
  document.head.appendChild(scriptElement);

});



onUnmounted(() => {
  window.removeEventListener("resize", updateScreenSize);
  document.removeEventListener("click", handleContentLinks);

});


onBeforeUnmount(() => {
  hideWidget();
});


// Watcher, který aktualizuje URL hash při změně tématu:
watch(selectedTopicKey, (newKey) => {
  if (newKey) {
    router.replace(`/napoveda#${newKey}`);
  }
});

</script>



<style scoped>

::v-deep(ul),
::v-deep(li), 
::v-deep(p) {
  text-align: justify;
  font-size: 15px;
  line-height: 26px;
}

::v-deep([id]:target::before) {
  content: "";
  display: block;
  height: 70px;
  margin-top: -70px;
  visibility: hidden;
}

::v-deep(h3) {
  margin-bottom: 10px;
  margin-top: 20px;
}

::v-deep(ul) {
  padding-left: 20px;
}

::v-deep(.result_csv_table) {
  width: 100%;
  border-collapse: collapse;
}

::v-deep(.result_csv_table th),
::v-deep(.result_csv_table td) {
  border: 1px solid #ccc;
  text-align: left;
  padding: 4px;
  width: 17%;
  vertical-align: top;
  font-size: 13px;
}

::v-deep(.result_csv_table th) {
  background-color: #f5f5f5;
  font-weight: bold;
}

.active {
  background-color: rgba(33, 150, 243, 0.2); /* Světle modrá */
  color: #1976d2 !important; /* Hlavní modrá barva Vuetify */
  font-weight: bold;
}

/* Pouze pro mobilní zobrazení */
@media (max-width: 960px) {
  ::v-deep(img) {
    max-width: 100%!important;
    height: auto!important; /* Obrázky budou max. tak široké jako obrazovka */
  }
}

::v-deep(mark) {
  background-color: yellow;
  color: black;
  font-weight: bold;
}

::v-deep(code) {
  background-color: #f0f0f0;
  padding: 2px 4px;
  border-radius: 4px;
}

/* Rámeček kolem obrázku */
/* ::v-deep(img) {
  border: 1px solid #c2d5ff;
  border-radius: 4px;
  padding: 2px;
  } */

</style>