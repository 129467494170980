<template>  
  <v-container v-if="!isLoading">
    <v-col cols="12">
      <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between align-center">
      <h1>Přihláška na {{ event.event_name }}</h1>
      <v-btn color="primary" text :to="'/soutez/' + event.id" style="height:50px; width:40px;" title="zpět na stránku soutěže"><v-icon size="28">mdi-arrow-left-circle</v-icon></v-btn>
    </v-col>
    
    <v-list density="compact">
      <v-list-item>
        Datum: {{ $formatDate(event.event_date_start) }} - {{ $formatDate(event.event_date_end) }}
      </v-list-item>
      <v-list-item v-if="bulletinFileLocation">
        <a :href="bulletinFileLocation" target="_blank">Propozice</a>
      </v-list-item>
    </v-list>

    <v-progress-circular v-if="isLoadingEdit && userStore.isLoggedIn" indeterminate color="primary" style="display:block; margin-bottom: 10px;"></v-progress-circular>
    <v-card v-if="Object.keys(allEntries).length">
      <v-card-title v-if="!entryClose">Editace přihlášky</v-card-title>

    <v-card-text>

      <v-row>

        <v-col cols="12" md="3">
        <!-- Rozevírací seznam pro výběr Entry -->
        <v-select
          :items="entryOptions"
          item-title="label"
          item-value="value"
          v-model="selectedEntryId"
          label="Vyber přihlášeného"
          @change="onEntrySelect">
        </v-select>
      </v-col>

      <v-col cols="12" md="2" v-if="mainEntry && !event.disable_si_cards">
        <v-text-field 
          v-model="mainEntry.si_number" 
          label="Číslo čipu"
          type="number"
          :rules="[rules.siNumber]"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <!-- <div class="d-flex justify-space-between align-center">    Zobrazení ceny pro hlavní kategorii
        <v-card-title>{{ mainrace.race_name }}</v-card-title>
        <template v-if="selectedCategories[mainRace.id]">
          <span style="color:green">
            {{ mainRace.categories.find(cat => cat.id === selectedCategories[mainRace.id])?.fee }} Kč
          </span>
        </template>
      </div> -->

      <v-col cols="12" md="2" v-if="mainRace">   
      <!-- Výběr kategorie pro Main Entry -->
      <v-tooltip location="top center" origin="auto" text="Slouží k rozřazení mezi přihlášené, je nezávislá na etapách">
        <template v-slot:activator="{ props }">
          <v-select
            v-model="selectedCategories[mainRace.id]"
            :items="filteredMainRaceCategories"
            item-title="category_name"
            item-value="id"
            label="Vyber hlavní kategorii"
            outlined
            dense
            v-bind="props"
            >
          </v-select>
        </template>
      </v-tooltip>  
    </v-col>

    <v-col cols="12" md="3" v-if="mainEntry">
      <v-text-field 
        v-model="mainEntry.note" 
        label="Poznámka pro pořadatele"
        outlined
        dense
        maxlength="80"
        counter 
      ></v-text-field>
    </v-col>

    <v-col cols="12" md="2" v-if="(mainEntry && !event.disable_si_cards && siRentData && (siRentData.price !== null || siRentChecked))">
      <v-checkbox
        v-model="siRentChecked"
        label="Půjčit čip"
        dense
      ></v-checkbox>
    </v-col>
  </v-row>

    <!-- Sekce pro Other Entries (ostatní závody) -->
    <v-row justify="center">
      <v-col v-for="race in filteredRaces" :key="race.id" cols="12" md="3" style="background-color:#EDF5FF">
        <v-card class="ma-2">
          <div class="d-flex justify-space-between align-center">
            <v-card-title class="pb-0">{{ race.race_name }}</v-card-title>
            <template v-if="selectedCategories[race.id]">
              <span style="color:green">
                {{
                  (() => {
                    const category = race.categories.find(cat => cat.id === selectedCategories[race.id]);
                    if (!category) return "0"; // Pokud kategorie neexistuje, vrátíme "0"
                    
                    const fee = mainEntry && mainEntry.termin_prihl === "2" && category.increased_fee 
                      ? category.increased_fee 
                      : category.fee;

                    return fee ?? "0"; // Pokud fee není definováno, vrátíme "0"
                  })()
                }} Kč
              </span>
            </template>
          </div>
          <div style="font-size:11px" class="pl-4">{{ $formatDate(race.race_date) }}</div>

          <v-card-text>
            <v-select
              v-model="selectedCategories[race.id]"
              :items="race.categories"
              item-title="category_name"
              item-value="id"
              label="Vyber kategorii"
              outlined
              dense
            >
            </v-select>
            <v-select v-if="event.allow_start_choice" density="comfortable" :items="startChoices" v-model="selectedStarts[race.id]" label="Požadavek na start"></v-select>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>



    <v-col v-if="!isLoading && Object.keys(accommodationsByDate).length">
            <h2 class="py-3">Ubytování</h2>

            <!-- Nový oddíl pro zobrazení volných míst u všech variant ubytování s limitem -->
            <v-row>
              <v-col v-for="(item, index) in uniqueAccommodations" :key="index">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle :class="{ 'text-red': item.freeSpaces === 0 }">
                  Volná místa: {{ item.freeSpaces }} / {{ item.limit }}
                </v-list-item-subtitle>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2" v-for="(item, date) in accommodationsByDate" :key="'acc-' + date">
                <div class="d-flex justify-space-between">
                  <h3>{{ $formatDate(date) }}</h3>
                  <template v-if="selectedAccommodations[date]">
                    <span v-if="item.length > 1" style="color:green">
                      {{ item.find(acc => acc.id === selectedAccommodations[date])?.price }} Kč
                    </span>
                    <span v-else style="color:green">
                      {{ item[0].price }} Kč
                    </span>
                  </template>
                </div>
                <template v-if="item.length === 1">
                  <v-checkbox
                    v-model="selectedAccommodations[date]"
                    :label="item[0].name"
                    :true-value="item[0].id"
                    :false-value="''"
                    outlined
                    dense
                    clearable
                    @click="onSelectClick(date)"
                    hide-details
                  ></v-checkbox>
                </template>
                <template v-else>
                  <v-select
                    :items="item"
                    item-title="name"
                    item-value="id"
                    v-model="selectedAccommodations[date]"
                    label="Vyber ubytování"
                    outlined
                    dense
                    clearable
                    class="truncate"
                    @click="onSelectClick(date)"
                    hide-details
                  ></v-select>
                </template>
              </v-col>
            </v-row>
          </v-col>

          <!-- Sekce pro jídlo -->
          <v-col v-if="Object.keys(mealsByDate).length">
            <h2 class="py-3">Jídlo</h2>
            <v-row>
              <v-col cols="12" md="2" v-for="(meals, date) in mealsByDate" :key="'meal-' + date">
                <h3 class="mb-3">{{ $formatDate(date) }}</h3>
                <div v-for="mealType in ['Snídaně', 'Oběd', 'Večeře']" :key="mealType">
                  <v-subheader v-if="meals.filter(meal => meal.type === mealType && meal.price !== '').length">
                    {{ mealType }}
                    <span v-if="selectedMeals[date][mealType]" style="color:green">
                      {{ meals.find(meal => meal.id === selectedMeals[date][mealType]).price }} Kč
                    </span>
                  </v-subheader>
                  <template v-if="meals.filter(meal => meal.type === mealType).length === 1">
                    <v-checkbox
                      v-model="selectedMeals[date][mealType]"
                      :label="meals.find(meal => meal.type === mealType).name"
                      :true-value="meals.find(meal => meal.type === mealType).id"
                      :false-value="null"
                      outlined
                      dense
                      clearable
                      hide-details
                    ></v-checkbox>
                  </template>
                  <template v-else>
                    <v-select
                      v-if="meals.some(meal => meal.type === mealType)"
                      :items="meals.filter(meal => meal.type === mealType)"
                      item-title="name"
                      item-value="id"
                      v-model="selectedMeals[date][mealType]"
                      :label="'Vyber ' + mealType"
                      outlined
                      dense
                      clearable
                      hide-details
                      class="mb-2"
                    ></v-select>
                  </template>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-row class="d-flex justify-center mt-5" no-gutters>
            <v-col v-if="!entryClose">
              <div class="responsive-container">
                <v-btn @click="submitEntry" color="primary" :disabled="!isEditFormValid" :loading="isFormLoading" class="responsive-btn">Aktualizovat přihlášku</v-btn>
                <span class="price">Cena: {{ totalSumEdit }} Kč</span>
                <v-btn color="error" @click="openDeleteDialog" class="responsive-btn">Smazat přihlášku</v-btn>
              </div>
            </v-col>
          </v-row>


          <!-- Dialog pro potvrzení smazání přihlášky -->
          <v-dialog v-model="deleteDialog" persistent max-width="600px">
            <v-card>
              <v-card-title class="text-h5">Potvrzení smazání přihlášky</v-card-title>
              <v-card-text>Opravdu chceš smazat přihlášku pro <b>{{ currentEntry.mainEntry.first_name }} {{ currentEntry.mainEntry.last_name }}</b>? Tato akce je nevratná!</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="deleteDialog = false">Zrušit</v-btn>
                <v-btn color="red darken-1" text @click="deleteEntries">Opravdu smazat</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
        </v-card>


  


  <!-- ******************************************
  ****                                  ******
  ****  Část pro vytvoření přihlášky    ******  
  ****                                  ******  
  ********************************************* -->

  <v-progress-circular v-if="isLoadingCreate && userStore.isLoggedIn" indeterminate color="primary"></v-progress-circular>
  <v-alert
    title="Jednorázová přihláška"
    text="Vytváříš jednorázovou přihlášku, která se nepropojí s tvým účtem v robisu! Pokud máš v robisu účet, přihlas se, aby se přihláška propojila s tvým účtem! Účty zakládají předsedové oddílů. Jednorázovou přihlášku nemůžeš editovat a nebudou propojené tvé výsledky ze soutěže."
    type="warning"
    variant="tonal"
    v-if="!userStore.isLoggedIn"
  />
  <v-card v-if="!isLoadingCreate && Object.keys(users).length && !entryClose || !userStore.isLoggedIn && !entryClose && !event.auth_entry_only" style="background-color: #EDF5FF;">
    <v-row>
      <v-col cols="12" md="3" class="pb-0">
        <v-card-title>Vytvoření přihlášky</v-card-title>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn color="green" @click="dialogBulkEntry = true" v-if="users.length > 1" class="responsive-btn mt-2">Hromadná přihláška</v-btn>
      </v-col>
    </v-row>







    <v-dialog v-model="dialogBulkEntry" width="100%" :persistent="true">
      <v-card>
        <v-form @submit.prevent="submitBulkEntries" v-if="!isLoading">
          <v-row>
              <v-card-title class="pb-0 mt-2 ml-4">Hromadná přihláška</v-card-title>
            <v-col cols="12" md="4" class="mt-1">
              <v-text-field
                v-model="searchQuery"
                label="Vyhledej podle jména"
                density="compact"
                hide-details="true"
                clearable
              />
            </v-col>
          </v-row>
        <v-card-text class="pt-0">
          <!-- Nový formulář, který iteruje přes všechny userOptions -->
          <!-- Obal pro horizontální scrollování -->
          <!-- Obal pro horizontální scrollování -->
          <div class="scroll-container">
            <div class="scroll-content">
              <div
                v-for="user in pagedUserOptions"
                :key="user.value"
                class="user-row"
                :class="selectedBulkUsers.includes(user.value) ? 'bg-green-lighten-4' : ''"
              >   

                <span>
                  <v-checkbox v-model="selectedBulkUsers" density="default" hide-details="true" :value="user.value" class="ml-2" :class="selectedBulkUsers.includes(user.value) ? 'bg-green-darken-1' : 'bg-orange-lighten-2'" />
                </span>

                <span class="user-cell">
                  <div style="font-size:16px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 100%;">{{ user.title }}</div>
                </span>
                <span class="user-cell" v-if="!event.disable_si_cards">
                  <v-text-field
                    v-model="bulkEntries[user.value].si_number"
                    type="number"
                    label="Číslo čipu"
                    :rules="[rules.siNumber]"
                    density="compact"
                  ></v-text-field>
                </span>

                <span class="px-3" v-if="!event.disable_si_cards && siRentData && (siRentData.price !== null)">
                  <v-checkbox
                    v-model="bulkEntries[user.value].si_rent"
                    label="Půjčit čip"
                    density="compact"
                  ></v-checkbox>
                </span>

                <span class="user-cell">
                  <v-select
                    :items="bulkUserCategories[user.value]"
                    item-title="category_name"
                    item-value="id"
                    v-model="bulkEntries[user.value].selectedCategoryRace0"
                    label="Kategorie pro soutěž"
                    no-data-text="Žádné kategorie pro výběr"
                    density="compact"
                  ></v-select>




                </span>
                <span class="user-cell">
                  <v-text-field
                    v-model="bulkEntries[user.value].note"
                    maxlength="80"
                    counter
                    label="Poznámka pro pořadatele"
                    density="compact"
                  ></v-text-field>
                </span>


                <!-- Výběr kategorií pro jednotlivé závody -->

                <div class="user-cell" v-for="race in races" :key="'race-' + race.id + '-user-' + user.value">
                  <div class="race-select-container">
                    <v-select
                      :items="bulkUserRaceCategories[user.value]?.[race.id] || []"
                      item-title="category_name"
                      item-value="id"
                      v-model="bulkSelectedCategories[user.value][race.id]"
                      :label="'Kat. ' + race.race_name"
                      clearable
                      density="compact"
                      hide-details="true"
                    ></v-select>

                    <v-select v-if="event.allow_start_choice"
                      density="compact"
                      hide-details="true"
                      :items="startChoices"
                      v-model="bulkSelectedStarts[user.value][race.id]"
                      label="Požadavek na start"
                    ></v-select>
                  </div>
                </div>         




                      
                <div class="user-cell" v-for="(accommodations, date) in accommodationsByDate" :key="'bulk-acc-' + user.value + '-' + date">
                  <div class="race-select-container">
                    <h3>{{ $formatShortDate(date) }}</h3>
                    <!-- <template v-if="bulkSelectedAccommodations[user.value] && bulkSelectedAccommodations[user.value][date]">
                      <span v-if="accommodations.length > 1" style="color:green">
                        {{ accommodations.find(acc => acc.id === bulkSelectedAccommodations[user.value][date])?.price }} Kč
                      </span>
                      <span v-else style="color:green">
                        {{ accommodations[0].price }} Kč
                      </span>
                    </template>  -->
                    <template v-if="accommodations.length === 1">
                      <v-checkbox
                        v-model="bulkSelectedAccommodations[user.value][date]"
                        :label="accommodations[0].name"
                        :true-value="accommodations[0].id"
                        :false-value="''"
                        outlined
                        dense
                        clearable
                        @click="onSelectClick(date)"
                        density="compact"
                        hide-details="true"
                      ></v-checkbox>
                    </template>
                    <template v-else>
                      <v-select
                        :items="accommodations"
                        item-title="name"
                        item-value="id"
                        v-model="bulkSelectedAccommodations[user.value][date]"
                        label="Ubytování"
                        outlined
                        dense
                        clearable
                        @click="onSelectClick(date)"
                        density="compact"
                        hide-details="true"
                      ></v-select>
                    </template>
                  </div>
                </div>



                <div class="user-cell" v-for="(meals, date) in mealsByDate" :key="'bulk-meals-' + user.value + '-' + date">
                  <div class="race-select-container">
                  <h3>{{ $formatShortDate(date) }}</h3>
                  <div v-for="mealType in ['Snídaně', 'Oběd', 'Večeře']" :key="mealType">
                    <template v-if="meals.filter(meal => meal.type === mealType).length === 1">
                      <!-- <v-subheader v-if="meals.filter(meal => meal.type === mealType && meal.price !== '').length">
                        <span v-if="bulkSelectedMeals[user.value] && bulkSelectedMeals[user.value][date] && bulkSelectedMeals[user.value][date][mealType]" style="color:green">
                          {{ meals.find(meal => meal.id === bulkSelectedMeals[user.value][date][mealType]).price }} Kč
                        </span>
                      </v-subheader> -->
                      <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                          <v-checkbox
                            v-bind="props"
                            v-model="bulkSelectedMeals[user.value][date][mealType]"
                            :label="meals.find(meal => meal.type === mealType).name"
                            :true-value="meals.find(meal => meal.type === mealType).id"
                            :false-value="null"
                            clearable
                            density="compact"
                            hide-details="true"
                          ></v-checkbox>
                        </template>
                        {{ mealType }}
                      </v-tooltip>
                      
                    </template>
                    <template v-else>
                      <!-- <v-subheader v-if="meals.filter(meal => meal.type === mealType && meal.price !== '').length">
                        <span v-if="bulkSelectedMeals[user.value] && bulkSelectedMeals[user.value][date] && bulkSelectedMeals[user.value][date][mealType]" style="color:green">
                          {{ meals.find(meal => meal.id === bulkSelectedMeals[user.value][date][mealType]).price }} Kč
                        </span>
                      </v-subheader> -->
                      <v-select
                        v-if="meals.some(meal => meal.type === mealType)"
                        :items="meals.filter(meal => meal.type === mealType)"
                        item-title="name"
                        item-value="id"
                        v-model="bulkSelectedMeals[user.value][date][mealType]"
                        :label=mealType
                        clearable
                        density="compact"
                        hide-details="true"
                      ></v-select>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>

      <v-card-actions v-if="$vuetify.display.sm || $vuetify.display.xs">
        <v-btn color="grey-darken-3" text @click="dialogBulkEntry = false"><v-icon size="40">mdi-close-circle</v-icon></v-btn>
        <v-spacer/>
        <v-pagination
          v-model="currentPage"
          :length="Math.ceil((userOptions?.length || 1) / itemsPerPage)"
          :total-visible="0"
        />

        <v-spacer />
        <v-btn type="submit" color="success" :loading="isFormLoading"><v-icon size="40">mdi-send-circle</v-icon></v-btn>
      </v-card-actions>

      <v-card-actions v-else>
        <v-btn color="grey-darken-3" text @click="dialogBulkEntry = false">Zrušit</v-btn>
        <v-spacer/>
        <v-pagination
          v-model="currentPage"
          :length="Math.ceil((userOptions?.length || 1) / itemsPerPage)"
          :total-visible="5"
        />
        <v-spacer />
        <v-btn type="submit" color="success" :loading="isFormLoading">Odeslat přihlášky</v-btn>
        </v-card-actions>
      </v-form>

    <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>

    </v-card>
  </v-dialog>


    <v-card-text>
    
      <v-form @submit.prevent="submitEntries" v-if="!isLoading">
        <template v-if="userStore.isLoggedIn"> <!-- Pokud je uživatel přihlášený, zobrazí se tento formulář -->
          <v-row>
          <v-col cols="12" md="3">
            <!-- Rozevírací seznam pro výběr uživatele -->
            <v-select
              :items="userOptions"
              item-text="label"
              item-value="value"
              v-model="selectedUserId"
              label="Vyber uživatele"
              @change="onUserSelect">
            </v-select>
          </v-col>

          <v-col cols="12" md="2" v-if="!event.disable_si_cards">
            <v-text-field v-model="selectedUser.si_number" type="number" label="Číslo čipu" :rules="[rules.siNumber]"></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <v-tooltip location="top center" origin="auto" text="Slouží k rozřazení mezi přihlášené, je nezávislá na etapách">
              <template v-slot:activator="{ props }">
              <v-select
                :items="race0.categories"
                item-title="category_name"
                item-value="id"
                v-model="selectedCategoryRace0"
                label="Kategorie pro soutěž"
                @change="onCategoryRace0Select"
                :rules="categoryMain"
                no-data-text="Žádné kategorie pro výběr"
                v-bind="props">
              </v-select>
            </template>
          </v-tooltip>  

          </v-col>

          <v-col cols="12" md="3">
            <v-text-field v-model="selectedUser.note" maxlength="80" counter label="Poznámka pro pořadatele"></v-text-field>
          </v-col>

          <v-col cols="12" md="2" v-if="(!event.disable_si_cards && siRentData && (siRentData.price !== null))">
            <v-checkbox v-model="selectedUser.si_rent" label="Půjčit čip"></v-checkbox>
          </v-col>
        </v-row>

          <v-row justify="center">
            <v-col v-for="race in races" :key="race.id" cols="12" md="3" style="background-color:#EDF5FF"> <!-- Nastavte šířku sloupce podle počtu závodů, které chcete mít vedle sebe -->
              <v-card class="ma-2">


                <div class="d-flex justify-space-between align-center">
                  <v-card-title class="pb-0">{{ race.race_name }}</v-card-title>
                  <template v-if="selectedCategoriesForCreateEntry[race.id]">
                   
                    <span style="color:green">
                      {{
                        (() => {
                          const category = race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id]);
                          if (!category) return "0"; // Pokud kategorie neexistuje, vrátíme "0"

                          // Pokud jsme před entry_deadline_1, použijeme fee (nebo 0)
                          if (new Date() < new Date(race0.entry_deadline_1)) {
                            return category.fee ?? "0";
                          } 
                          
                          // Pokud jsme mezi entry_deadline_1 a entry_deadline_2
                          if (race0.entry_deadline_2 && new Date(race0.entry_deadline_1) < new Date() && new Date() < new Date(race0.entry_deadline_2)) {
                            return category.increased_fee ?? category.fee ?? "0"; // Použij increased_fee, pokud není, použij fee, jinak 0
                          } 
                          
                          // Jinak (po deadline_2 nebo pokud není deadline_2) použijeme běžné fee nebo 0
                          return category.fee ?? "0";
                        })()
                      }} Kč
                    </span>
                  </template>
                </div>
                <div style="font-size:11px" class="pl-4">{{ $formatDate(race.race_date) }}</div>

                <v-card-text>
                  <v-select
                    :items="race.categories"
                    item-title="category_name"
                    item-value="id"
                    v-model="selectedCategoriesForCreateEntry[race.id]"
                    label="Vyber kategorii"
                    :key="race.id"
                    clearable
                    density="comfortable"
                  ></v-select>
                  <v-select v-if="event.allow_start_choice" density="comfortable" hide-details="true" :items="startChoices" v-model="selectedStartsEntry[race.id]" label="Požadavek na start"></v-select>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
  
        </template>

        <template v-else>    <!-- Pokud uživatel není v robisu přihlášený, zobrazí se mu tento formulář -->

          <v-row>
            <v-col cols="12" md="2">
            <v-text-field v-model="selectedUser.first_name" label="Jméno" :rules= "requiredField"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field v-model="selectedUser.last_name" label="Příjmení" :rules= "requiredField"></v-text-field>
            </v-col>

            <!-- <v-col cols="12" md="2">
              <v-text-field v-model="selectedUser.index" label="Index"></v-text-field>
            </v-col> -->


            <v-col cols="12" md="2" v-if="!event.disable_si_cards">
              <v-text-field v-model="selectedUser.si_number" type="number" label="Číslo čipu" :rules="[rules.siNumber]"></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field v-model="selectedUser.birth_year" label="Rok narození" :rules="[rules.bornYear]"></v-text-field>
            </v-col>


            <v-col cols="12" md="2">
              <v-select v-model="selectedUser.competitor_gender" :items="genders" label="Pohlaví"></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-select
                :items="race0.categories"
                item-title="category_name"
                item-value="id"
                v-model="selectedCategoryRace0"
                label="Kategorie pro soutěž"
                @change="onCategoryRace0Select"
                no-data-text="Žádné kategorie pro výběr"
                :rules="categoryMain">
              </v-select>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="selectedUser.email" label="Email" :rules="validEmail"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="selectedUser.note" maxlength="80" counter label="Poznámka pro pořadatele"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <CountrySelect v-model="selectedUser.country" label="Země" />
            </v-col>
            <v-col cols="12" md="2" v-if="(!event.disable_si_cards && siRentData && (siRentData.price !== null))">
              <v-checkbox v-model="selectedUser.si_rent" label="Půjčit čip"></v-checkbox>
            </v-col>
          </v-row>
          
          <v-row justify="center">
            <v-col v-for="race in races" :key="race.id" cols="12" md="3" style="background-color:#EDF5FF"> 
              <v-card class="ma-2">
              <div class="d-flex justify-space-between align-center">
                <v-card-title>{{ race.race_name }}</v-card-title>
                <template v-if="selectedCategoriesForCreateEntry[race.id]">
                        
                  <span style="color:green">
                    {{
                      new Date() < new Date(race0.entry_deadline_1)
                        ? race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.fee
                        : race0.entry_deadline_2 && new Date(race0.entry_deadline_1) < new Date() && new Date() < new Date(race0.entry_deadline_2) && race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.increased_fee
                          ? race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.increased_fee
                          : race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.fee
                    }} Kč
                  </span>
                </template>
              </div>

                <v-card-text>

                  <v-select
                    :items="race.categories"
                    item-title="category_name"
                    item-value="id"
                    v-model="selectedCategoriesForCreateEntry[race.id]"
                    label="Vyberte kategorii"
                    :key="race.id"
                    clearable
                    density="comfortable"
                  ></v-select>
                  <v-select v-if="event.allow_start_choice" density="comfortable" hide-details="true" :items="startChoices" v-model="selectedStartsEntry[race.id]" label="Požadavek na start"></v-select>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </template>

        <v-col v-if="!isLoading && Object.keys(accommodationsByDate).length">
            <h2 class="py-3">Ubytování</h2>

            <!-- Nový oddíl pro zobrazení volných míst u všech variant ubytování s limitem -->
            <v-row>
              <v-col v-for="(item, index) in uniqueAccommodations" :key="index">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle :class="{ 'text-red': item.freeSpaces === 0 }">
                  Volná místa: {{ item.freeSpaces }} / {{ item.limit }}
                </v-list-item-subtitle>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2" v-for="(item, date) in accommodationsByDate" :key="'acc-' + date">
                <div class="d-flex justify-space-between">
                  <h3>{{ $formatDate(date) }}</h3>
                  <template v-if="selectedAccommodations[date]">
                    <span v-if="item.length > 1" style="color:green">
                      {{ item.find(acc => acc.id === selectedAccommodations[date])?.price }} Kč
                    </span>
                    <span v-else style="color:green">
                      {{ item[0].price }} Kč
                    </span>
                  </template>
                </div>
                <template v-if="item.length === 1">
                  <v-checkbox
                    v-model="selectedAccommodations[date]"
                    :label="item[0].name"
                    :true-value="item[0].id"
                    :false-value="''"
                    outlined
                    dense
                    clearable
                    @click="onSelectClick(date)"
                  ></v-checkbox>
                </template>
                <template v-else>
                  <v-select
                    :items="item"
                    item-title="name"
                    item-value="id"
                    v-model="selectedAccommodations[date]"
                    label="Vyber ubytování"
                    outlined
                    dense
                    clearable
                    class="truncate"
                    @click="onSelectClick(date)"
                  ></v-select>
                </template>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="Object.keys(mealsByDate).length">
            <h2 class="py-3">Jídlo</h2>
            <v-row>
              <v-col cols="12" md="2" v-for="(meals, date) in mealsByDate" :key="'meal-' + date">
                <h3 class="mb-3">{{ $formatDate(date) }}</h3>
                <div v-for="mealType in ['Snídaně', 'Oběd', 'Večeře']" :key="mealType">
                  <template v-if="meals.filter(meal => meal.type === mealType).length === 1">
                    <v-subheader v-if="meals.filter(meal => meal.type === mealType && meal.price !== '').length">
                    {{ mealType }}
                    <span v-if="selectedMeals[date][mealType]" style="color:green">
                      {{ meals.find(meal => meal.id === selectedMeals[date][mealType]).price }} Kč
                    </span>
                  </v-subheader>
                    <v-checkbox
                      v-model="selectedMeals[date][mealType]"
                      :label="meals.find(meal => meal.type === mealType).name"
                      :true-value="meals.find(meal => meal.type === mealType).id"
                      :false-value="null"
                      outlined
                      dense
                      clearable
                    ></v-checkbox>
                  </template>
                  <template v-else>
                    <v-subheader v-if="meals.filter(meal => meal.type === mealType && meal.price !== '').length">
                    {{ mealType }}
                    <span v-if="selectedMeals[date][mealType]" style="color:green">
                      {{ meals.find(meal => meal.id === selectedMeals[date][mealType]).price }} Kč
                    </span>
                  </v-subheader>
                    <v-select
                      v-if="meals.some(meal => meal.type === mealType)"
                      :items="meals.filter(meal => meal.type === mealType)"
                      item-title="name"
                      item-value="id"
                      v-model="selectedMeals[date][mealType]"
                      :label="'Vyber ' + mealType"
                      outlined
                      dense
                      clearable
                    ></v-select>
                  </template>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-btn type="submit" :loading="isFormLoading" class="responsive-btn" color="primary" :disabled="!isFormValid">Přihlásit</v-btn>
          <span class="price">Cena: {{ totalSumCreate }} Kč</span>
      </v-form>
      <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
    </v-card-text>
  </v-card>
  <v-alert type="error" v-if="event.auth_entry_only && !userStore.isLoggedIn">Přihláška na tuto soutěž je povolena pouze pro přihlášené uživatele v robisu.</v-alert>
  <v-alert variant="tonal" type="info" v-if="entryClose && !isLoading && !isLoadingCreate" class="mt-2">{{ entryClose }}</v-alert> 
  </v-container>
  <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
  <!-- </template> -->
</template>


<script setup>
import { ref, onMounted, computed, watch, reactive, watchEffect } from 'vue';
import apiClient from '/api.js';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '/auth.js';
import CountrySelect from '@/components/shared/CountrySelect.vue';
import { genders } from '@/components/const/gender.js';
import {useSnackbarStore} from '@/stores/snackbarStore'



const isLoading = ref(true);  // Indikátor načítání
const isLoadingCreate = ref(true);  // Indikátor načítání pro vytvoření přihlášky
const isLoadingEdit = ref(true);  // Indikátor načítání pro editaci přihlášky

const snackbar = useSnackbarStore()
const route = useRoute();
const router = useRouter(); // Získání instance routeru

const userStore = useAuthStore();
const eventId = route.params.id;
const event = ref({ entry_is_open: false }); // údaje o eventu
const races = ref([]);
const allRaces = ref([]);
const race0 = ref({});
const users = ref([]);
const selectedUser = ref({ si_number: '', note: '', competitor_gender: '', birth_year: null, competitor_club:null, country: 'CZE' });
const selectedUserId = ref(null);


const entries = ref([]);
const selectedCategories = ref({});
const selectedCategoriesForCreateEntry = ref({});

const selectedCategoryRace0 = ref(null);

const mainEntry = ref(null); // Přidali jsme reaktivní referenci pro mainEntry
const mainRace = computed(() => allRaces.value[0]);
const selectedStarts = ref({});
const selectedStartsEntry = ref({});


const accommodationsByDate = ref({});
const mealsByDate = ref({});
const selectedAccommodations = ref({});
const selectedMeals = ref({});

const deleteDialog = ref(false);

function openDeleteDialog() {
  deleteDialog.value = true;
}



const startChoices = [
      { title: 'Nemám preferenci', value: '' },
      { title: 'První startující', value: '0' },
      { title: 'První skupina', value: '1' },
      { title: 'Druhá skupina', value: '2' },
      { title: 'Třetí skupina', value: '3' },
      { title: 'Poslední startující', value: '4' },
    ];
const allEntries = ref([]);
const selectedEntryId = ref(null);
const currentEntry = ref(null);

const errorMessage = ref('');

const rules = {
  siNumber: value => !value || /^\d{4,8}$/.test(value) || 'Číslo SI musí mít 4 až 8 cifer.',
  bornYear: value => { const currentYear = new Date().getFullYear();
    return value >= 1900 && value <= currentYear || `Rok narození musí být mezi 1900 a ${currentYear}.`;}
};


// Výpočetní vlastnost pro kontrolu, zda jsou registrace uzavřené nebo termíny pro race0 vypršely
const entryClose = computed(() => {
  if (isLoading.value) {
    return '';  // Nezobrazujeme žádnou zprávu, dokud se načítají data
  }
  if (!event.value.entry_is_open) {
    return 'Přihlášky včetně editace na událost nejsou povolené.';
  }
  const now = new Date();
  const deadline1 = new Date(race0.value.entry_deadline_1);
  const deadline2 = new Date(race0.value.entry_deadline_2);
  if (now > deadline1 && now > deadline2) {
    return 'Přihlášky jsou uzavřené';
  }
  return '';  // Vrátí prázdný řetězec, pokud neexistuje žádný problém
});



// Vypočítaná vlastnost pro generování možností rozevíracího seznamu Entry
const entryOptions = computed(() => allEntries.value.map(entry => ({
  value: entry.user,
  label: `${entry.mainEntry.last_name} ${entry.mainEntry.first_name} (${entry.mainEntry.competitor_index})`
})));


const siRentChecked = ref(false); // Místo pro sledování checkboxu


function onEntrySelect(selectedUserId) {
  const selected = allEntries.value.find(entry => entry.user === selectedUserId);
  currentEntry.value = selected;
  mainEntry.value = selected.mainEntry;

  // Zkontrolujeme všechny závody a nastavíme výchozí hodnoty pro start_choice
  allRaces.value.forEach(race => {
    selectedStarts.value[race.id] = '';  // Nastavení defaultní hodnoty pro startovní skupiny
  });

  // Aktualizace selectedCategories na základě kategorií vybraného Entry
  selectedCategories.value = {};
  selected.otherEntries.forEach(oe => {
    selectedStarts.value[oe.race] = oe.start_preference;
    selectedCategories.value[oe.race] = oe.competitor_category;
  });

  // Nastavte vybranou kategorii pro hlavní závod
  if (selected.mainEntry && selected.mainEntry.race && selected.mainEntry.competitor_category) {
    selectedCategories.value[selected.mainEntry.race] = selected.mainEntry.competitor_category;
  }

  // Zkontrolujte, zda má uživatel registrovaný si_rent v race_services
  if (siRentData.value && selected.mainEntry.race_service.includes(siRentData.value.id)) {
    siRentChecked.value = true; // Uživatel má přihlášený si_rent
  } else {
    siRentChecked.value = false; // Uživatel nemá přihlášený si_rent
  }

  // Resetování předvybraných hodnot pro ubytování a jídlo
  Object.keys(accommodationsByDate.value).forEach(date => {
    selectedAccommodations.value[date] = null;
  });
  Object.keys(mealsByDate.value).forEach(date => {
    selectedMeals.value[date] = {};
    mealsByDate.value[date].forEach(meal => {
      selectedMeals.value[date][meal.type] = null;
    });
  });



  // Nastavení předvybraných ubytování a jídel podle dat uživatele
  selected.accommodations.forEach(accommodationId => {
    const dateKey = Object.keys(accommodationsByDate.value).find(date => 
      accommodationsByDate.value[date].some(acc => acc.id === accommodationId));
    if (dateKey) {
      selectedAccommodations.value[dateKey] = accommodationId;
    }
  });

  selected.food.forEach(foodId => {
    const dateKey = Object.keys(mealsByDate.value).find(date =>
      mealsByDate.value[date].some(meal => meal.id === foodId));
    if (dateKey) {
      const mealType = mealsByDate.value[dateKey].find(meal => meal.id === foodId).type;
      selectedMeals.value[dateKey][mealType] = foodId;
    }
  });

}




// Funkce pro načtení Entries
async function fetchEntries() {
  isLoadingEdit.value = true;
  try {
    const response = await apiClient.get(`event/${eventId}/entry/edit/`);
    allEntries.value = response.data;
    // Nastavení prvního Entry jako výchozího vybraného
    if (allEntries.value.length > 0) {
      selectedEntryId.value = allEntries.value[0].user;
      currentEntry.value = allEntries.value[0];

    }
  } catch (error) {
    console.error('Failed to load entries:', error);
    errorMessage.value = 'Nepodařilo se načíst přihlášky.';
  } finally {
    isLoadingEdit.value = false;
  }
}
  


// Computed property pro získání všech závodů kromě hlavního
const filteredRaces = computed(() => {
  return allRaces.value.slice(1).map(race => {
    const userAge = calculateUserAge(mainEntry.value?.birth_year);
    const userSex = mainEntry.value?.competitor_gender;
    const isSameClub = isUserInSameClub(mainEntry.value?.competitor_club, event.value.club);

    const filteredCategories = filterCategoriesByAgeAndSex(
      race.categories, 
      userAge, 
      userSex, 
      isSameClub
    );

    // Přidání prázdné možnosti do pole kategorií po filtraci
    const categoriesWithEmptyOption = [{ id: null, category_name: "Nepřihlášen/a", option: "" }, ...filteredCategories];
    
    return {
      ...race,
      categories: categoriesWithEmptyOption,
    };
  });
});

// Vypočítaná vlastnost pro filtrované kategorie hlavního závodu
const filteredMainRaceCategories = computed(() => {
  // Získání hlavního závodu
  const mainRace = allRaces.value[0] || { categories: [] };
  const userAge = calculateUserAge(mainEntry.value?.birth_year);
  const userSex = mainEntry.value?.competitor_gender;
  const isSameClub = isUserInSameClub(mainEntry.value?.competitor_club, event.value.club);

  // Filtrace kategorií hlavního závodu
  return filterCategoriesByAgeAndSex(
    mainRace.categories,
    userAge,
    userSex,
    isSameClub
  );
});

const calculateUserAge = (birthYear) => new Date().getFullYear() - birthYear;

const isUserInSameClub = (userClub, eventOrganizerClub) => {
  return userClub === eventOrganizerClub;
};


// Function to filter categories by age and sex
const filterCategoriesByAgeAndSex = (categories, userAge, userSex, isSameClub) => {
  const availableCategories = [];

    // Podmínka pro kategorii MDR
    if (userAge <= 13) {
      availableCategories.push('MDR');
      }
    // pro ostatní kategorie podle typu option
    if (userSex === 'F') {
      if (userAge <= 7) {
        availableCategories.push('W7', 'W9', 'W12', 'W14', 'W16', 'W19', 'W21');
      } else if (userAge <= 9) {
        availableCategories.push('W9', 'W12', 'W14', 'W16', 'W19', 'W21');
      } else if (userAge <= 12) {
        availableCategories.push('W12', 'W14', 'W16', 'W19', 'W21');
      } else if (userAge <= 14) {
        availableCategories.push('W14', 'W16', 'W19', 'W21');
      } else if (userAge <= 16) {
        availableCategories.push('W16', 'W19', 'W21');
      } else if (userAge <= 19) {
        availableCategories.push('W19', 'W21');
      } else if (userAge <= 34) {
        availableCategories.push('W21');
      } else if (userAge <= 44) {
        availableCategories.push('W35', 'W21');
      } else if (userAge <= 54) {
        availableCategories.push('W45', 'W35', 'W21');
      } else if (userAge <= 64) {
        availableCategories.push('W55', 'W45', 'W35', 'W21');
      } else if (userAge <= 99) {
        availableCategories.push('W65', 'W55', 'W45', 'W35', 'W21');
      }
    } else {
      if (userAge <= 7) {
        availableCategories.push('M7', 'M9', 'M12', 'M14', 'M16', 'M19', 'M21');
      } else if (userAge <= 9) {
        availableCategories.push('M9', 'M12', 'M14', 'M16', 'M19', 'M21');
      } else if (userAge <= 12) {
        availableCategories.push('M12', 'M14', 'M16', 'M19', 'M21');
      } else if (userAge <= 14) {
        availableCategories.push('M14', 'M16', 'M19', 'M21');
      } else if (userAge <= 16) {
        availableCategories.push('M16', 'M19', 'M21');
      } else if (userAge <= 19) {
        availableCategories.push('M19', 'M21');
      } else if (userAge <= 39) {
        availableCategories.push('M21');
      } else if (userAge <= 49) {
        availableCategories.push('M40', 'M21');
      } else if (userAge <= 59) {
        availableCategories.push('M50', 'M40', 'M21');
      } else if (userAge <= 69) {
        availableCategories.push('M60', 'M50', 'M40', 'M21');
      } else if (userAge <= 99) {
        availableCategories.push('M70', 'M60', 'M50', 'M40', 'M21');
      }
    }
    // Podmínka pro kategorii ostatni
    if (userAge <= 200) {
        availableCategories.push('OTHERS');
    }  
    // Přidání kategorie ORG, pokud je uživatel ze stejného oddílu
    if (isSameClub) {
      availableCategories.push('ORG');
    }
    // Vrátíme kategorie ve stejném pořadí, v jakém byly přidány do availableCategories
    // Zachování pořadí kategorií podle availableCategories
    return availableCategories
      .map(option => categories.find(category => category.option === option)) // Najdeme odpovídající objekty kategorií
      .filter(category => category !== undefined); // Odstraníme `undefined`, pokud některé kategorie nejsou v seznamu
  };




// sleduje změnu přihlášky z rozevíracího seznamu
watch(() => selectedEntryId.value, (newId) => {
  onEntrySelect(newId);

});


onMounted(async () => {
  try {
    await Promise.all([
      fetchEvent(eventId),
      fetchRaceServices(eventId),
      fetchEntries(),
      fetchUsers(eventId),
    ]);
    
    // Zde nastavte vybrané ubytování a jídlo podle prvního uživatele
    if (allEntries.value.length > 0) {
      onEntrySelect(selectedEntryId.value);
    }
  } catch (error) {
    console.error('Chyba při načítání dat:', error);
  }
});


// Kontrola vyplněných údajů pro přihlášeného uživatele
const isEditFormValid = computed(() => {
  if (mainEntry.value) {
    return rules.siNumber(mainEntry.value.si_number) === true;
  }
  return false;
});

// Po odeslání se znepřístupní tlačítko, dokud se data nevrátí z backendu
const isFormLoading = ref(false);

async function submitEntry() {
  const currentSelectedEntry = allEntries.value.find(entry => entry.user === selectedEntryId.value);

  if (!currentSelectedEntry) {
    console.error("Vybrané entry nebylo nalezeno.");
    return;
  }

  // Kontrola, zda je vyplněno si_number nebo zaškrtnutý si_rent
  if (!mainEntry.value.si_number && !siRentChecked.value) {
    errorMessage.value = 'Musíš buď vyplnit číslo čipu, nebo zaškrtnout půjčení čipu!';
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    return; // Ukončení odesílání přihlášky
  }

  // spuštění načítání požadavku
  isFormLoading.value = true;


  let entriesToUpdate = [];

  // Shromáždění ID z ubytování a jídla
  const raceServiceIds = [
    ...Object.values(selectedAccommodations.value).filter(Boolean), // ID ubytování
    ...Object.values(selectedMeals.value).flatMap(mealTypes => Object.values(mealTypes)).filter(Boolean) // ID jídla
  ];

  // Přidání si_rent do race_services, pokud je checkbox zaškrtnutý
  if (siRentChecked.value && siRentData.value) {
    raceServiceIds.push(siRentData.value.id);  // Přidat si_rent ID
  } else {
    // Pokud není zaškrtnutý, odeber si_rent ID z race_services, pokud tam je
    const index = raceServiceIds.indexOf(siRentData.value.id);
    if (index > -1) {
      raceServiceIds.splice(index, 1);
    }
  }
  
  // Přidání hlavního záznamu s aktualizací vybraných dat
  entriesToUpdate.push({
    ...currentSelectedEntry.mainEntry,
    competitor_category: selectedCategories.value[currentSelectedEntry.mainEntry.race], // Vybraná kategorie pro hlavní záznam
    id: currentSelectedEntry.mainEntry.id, // ID hlavního záznamu
    race: currentSelectedEntry.mainEntry.race, // ID závodu hlavního záznamu
    si_number: mainEntry.value.si_number === "" ? null : mainEntry.value.si_number,
    note: mainEntry.value.note,
  });

  // Přidání ostatních záznamů s aktualizací vybraných dat
  currentSelectedEntry.otherEntries.forEach(otherEntry => {
    entriesToUpdate.push({
      ...otherEntry,
      competitor_category: selectedCategories.value[otherEntry.race] === "" ? null : selectedCategories.value[otherEntry.race],
      id: otherEntry.id, // ID záznamu
      race: otherEntry.race, // ID závodu
      si_number: mainEntry.value.si_number === "" ? null : mainEntry.value.si_number, // Číslo čipu z hlavního záznamu (předpokládáme stejné pro všechny závody)
      start_preference: selectedStarts.value[otherEntry.race],
      note: mainEntry.value.note,

    });
  });

  // Kontrola pro závody, které nemají odpovídající entry, ale byla vybrána kategorie
  filteredRaces.value.forEach(race => {
    if (selectedCategories.value[race.id] && !currentSelectedEntry.otherEntries.some(entry => entry.race === race.id)) {
      entriesToUpdate.push({
        id: null,
        race: race.id,
        competitor_category: selectedCategories.value[race.id] === "" ? null : selectedCategories.value[race.id],
        si_number: mainEntry.value.si_number === "" ? null : mainEntry.value.si_number,
        start_preference: selectedStarts.value[race.id],
      });
    }
    
  });

  let submissionData = {
    role: selectedEntryId.value,
    entries: entriesToUpdate,
    race_services: raceServiceIds,
  };


  try {
    await apiClient.put(`event/${eventId}/entry/edit/`, submissionData);
    snackbar.showSnackbar("Přihláška byla aktualizována.");
    errorMessage.value = '';
    isFormLoading.value = false; // Dočasně, protože jinak se to loaduje kvůli pomalému načítání položek níže a vypadá to divně 
    await fetchRaceServices(eventId);
    await fetchEntries(); // Znovu načtení dat o uživateli
    // Aktualizace údajů uživatele na toho prvního v seznamu
    if (allEntries.value.length > 0) {
      selectedEntryId.value = allEntries.value[0].user;
      onEntrySelect(selectedEntryId.value); // Aktualizace formuláře s nově vybraným uživatelem
    }

  } catch (error) {
    console.error("Chyba při odesílání přihlášky:", error);
    if (error.response) {
      if (error.response.status === 423) {
        errorMessage.value = 'Limit přihlášek pro toto ubytování byl vyčerpán.';
      } else if (error.response.status === 409) {
        errorMessage.value = error.response.data.error;
        await fetchEntries(); // Znovu načtení dat o uživateli
      } else {
        errorMessage.value = 'Přihlášku se nepodařilo aktualizovat.';
      }
    } else {
      errorMessage.value = 'Přihlášku se nepodařilo aktualizovat.';
    }
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
  } finally {
    isFormLoading.value = false;  // Ukončení načítání po zpracování odpovědi
  }
}

async function deleteEntries() {
 // Najděte vybrané entry pro uživatele
 const selectedUserEntry = allEntries.value.find(entry => entry.user === selectedEntryId.value);
  
  if (!selectedUserEntry) {
    console.error("Entry pro vybraného uživatele nebylo nalezeno.");
    return;
  }

  // Získání ID všech přihlášek (včetně hlavního entry a ostatních entry) pro smazání
  const entryIdsToDelete = [selectedUserEntry.mainEntry.id, ...selectedUserEntry.otherEntries.map(entry => entry.id)];

  // Odeslání požadavku na smazání na server
  try {
    await apiClient.delete(`event/${eventId}/entry/edit/`, {
      data: { entry_id: entryIdsToDelete }
    });
    console.log('Všechny přihlášky pro vybraného uživatele byly úspěšně smazány.');
    deleteDialog.value = false;
    localStorage.setItem('successMessage', 'Přihláška byla úspěšně smazána.');
    router.push(`/soutez/${eventId}`);
    // Zde můžete přidat logiku pro aktualizaci uživatelského rozhraní nebo přesměrování
  } catch (error) {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    errorMessage.value = 'Přihlášku se nepodařilo smazat.';
    console.error("Chyba při mazání přihlášek:", error.response?.data?.error || error.message);
  }
}


///////////////////////////////////////////////////////////////
//
//          Vytvoření přihlášek
//
///////////////////////////////////////////////////////////////


const categoryMain = [v => !!v || 'Musíš vybrat kategorii pro celou soutěž'];
const validEmail = [value => !value || /^[\w-.]+@([\w-]+\.)+[\w-]{2,24}$/.test(value) ? true : 'Neplatný email'];
const requiredField = [(v) => !!v || 'Toto pole je povinné'];


// Zobrazení select pro výběr uživatele
const userOptions = computed(() =>
  users.value.map(user => ({
    title: `${user.last_name} ${user.first_name} (${user.competitor_index})`,
    value: user.id,
  }))
);

// Aktualizace kategorií podle věku a pohlaví vybraného uživatele
const updateCategoriesForUser = () => {
  if (!event.value?.races || event.value.races.length === 0 || !selectedUser.value) return;

  // Získání aktuálního věku a pohlaví uživatele
  const userAge = calculateUserAge(selectedUser.value.birth_year);
  const userSex = selectedUser.value.competitor_gender;
  const userClub = selectedUser.value.competitor_club;
  const eventOrganizerClub = event.value.club;

  // Ověříme, zda je uživatel ze stejného oddílu jako organizátor
  const isSameClub = isUserInSameClub(userClub, eventOrganizerClub);

  // Aktualizace kategorií pro race0 (hlavní závod)
  race0.value = {
    ...race0.value,
    categories: filterCategoriesByAgeAndSex(event.value.races[0].categories, userAge, userSex, isSameClub),
  };

  // Aktualizace kategorií pro všechny závody
  races.value = event.value.races.slice(1).map(race => ({
    ...race,
    categories: [{ id: null, category_name: "Nepřihlásit" }, ...filterCategoriesByAgeAndSex(race.categories, userAge, userSex, isSameClub)],
  }));
};

// Sledování změn v ID vybraného uživatele a aktualizace kategorií
watch(selectedUserId, (newUserId) => {
  const user = users.value.find(u => u.id === newUserId);
  selectedUser.value = user || {}; // Aktualizace vybraného uživatele

  selectedCategoryRace0.value = null; // Reset hlavní kategorie
  Object.keys(selectedCategoriesForCreateEntry.value).forEach(key => {
    selectedCategoriesForCreateEntry.value[key] = undefined;
  });

  updateCategoriesForUser(); // Pouze aktualizace kategorií, bez volání API
});

// Sledování změn v roce narození a pohlaví -> RESETUJEME kategorie a přepočítáme možnosti
watch(
  [() => selectedUser.value.birth_year, () => selectedUser.value.competitor_gender],
  (newValues, oldValues) => {
    const [newBornYear, newSex] = newValues;
    const [oldBornYear, oldSex] = oldValues;

    if (newBornYear && newSex && (newBornYear !== oldBornYear || newSex !== oldSex)) {
      console.log('Rok narození nebo pohlaví bylo změněno, resetuji výběr kategorií.');

      // Reset hlavní kategorie a kategorií pro všechny závody
      selectedCategoryRace0.value = null;
      Object.keys(selectedCategoriesForCreateEntry.value).forEach(key => {
        selectedCategoriesForCreateEntry.value[key] = undefined;
      });

      // Aktualizace dostupných kategorií
      updateCategoriesForUser();
    }
  },
  { deep: true }
);

// Sledování změny hlavní kategorie a automatické nastavení stejné kategorie ve všech závodech
watch(selectedCategoryRace0, (newCategoryId) => {
  if (!newCategoryId || !race0.value) return;

  // Najdeme název kategorie podle jejího ID
  const selectedCategoryName = race0.value.categories.find(category => category.id === newCategoryId)?.category_name;

  if (selectedCategoryName) {
    // Pro všechny závody nastavíme stejnou kategorii (pokud existuje)
    races.value.forEach(race => {
      const matchingCategory = race.categories.find(category => category.category_name === selectedCategoryName);
      if (matchingCategory) {
        selectedCategoriesForCreateEntry.value[race.id] = matchingCategory.id;
      }
    });
  }
});



const fetchUsers = async (eventId) => {
  try {
    const response = await apiClient.get(`event/${eventId}/entry/create/`);
    // Pokud je odpověď pole, použijeme ji přímo, pokud ne, vytvoříme z odpovědi pole
    users.value = Array.isArray(response.data) ? response.data : [response.data];

    // Zkontrolujeme, zda máme alespoň jednoho uživatele, a pokud ano, nastavíme ho jako vybraného
    if (users.value.length > 0) {
      selectedUserId.value = users.value[0].id;
      selectedUser.value = users.value[0];
    }
    isLoadingCreate.value = false;
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};


const fetchEvent = async (eventId) => {
  try {
    const response = await apiClient.get(`event/?event_id=${eventId}`);
    
    if (!response.data) {
      throw new Error('Neplatná odpověď z API');
    }

    const eventData = response.data;
    event.value = eventData;

    // Pokud event obsahuje závody, zpracujeme je
    if (eventData.races && eventData.races.length > 0) {
      // Seřadíme závody podle race_date
      const sortedRaces = eventData.races.sort((a, b) => new Date(a.race_date) - new Date(b.race_date));

      // První závod s nejnižším ID
      const firstRace = sortedRaces[0];

      // Získání věku a pohlaví uživatele
      const userAge = calculateUserAge(selectedUser.value.birth_year);
      const userSex = selectedUser.value.competitor_gender;
      const userClub = selectedUser.value?.club;  // Oddíl uživatele
      const eventOrganizerClub = eventData.club;  // Oddíl organizátora

      // Ověříme, zda je uživatel ze stejného oddílu jako organizátor
      const isSameClub = isUserInSameClub(userClub, eventOrganizerClub);

      // Nastavení prvního závodu
      race0.value = {
        ...firstRace,
        categories: filterCategoriesByAgeAndSex(firstRace.categories, userAge, userSex, isSameClub),
      };

      // Seřazení zbývajících závodů podle data a přidání kategorie "Nepřihlásit"
      races.value = sortedRaces.slice(1).map(race => ({
        ...race,
        categories: [{ id: null, category_name: "Nepřihlásit" }, ...filterCategoriesByAgeAndSex(race.categories, userAge, userSex, isSameClub)],
      }));

      // Zpracování závodů pro výběr kategorií
      allRaces.value = eventData.races.map(race => ({
        ...race,
        categories: [
          { id: null, category_name: "", option: "", fee: 0 },
          ...race.categories.sort((a, b) => a.category_name.localeCompare(b.category_name)),
        ],
      }));

      // Nastavení vybraných kategorií na základě přihlášek
      allRaces.value.forEach(race => {
        const foundEntry = entries.value.find(entry => entry.race === race.id);
        if (foundEntry) {
          selectedCategories.value[race.id] = foundEntry.competitor_category;
        }
      });

      // Uložení termínu přihlášek z prvního závodu
      if (allRaces.value.length > 0) {
        mainEntry.value = { termin_prihl: allRaces.value[0].entry_deadline_1 };
      }
    }

    isLoading.value = false;
  } catch (error) {
    console.error('Chyba při načítání dat o eventu, závodech nebo kategoriích:', error);
  }
};


// Získání URL na Propozice, pokud existují
const BaseUrl = ref(process.env.VUE_APP_API_BASE_URL.replace(/\/+$/, '').replace(/\/api\/?$/, '')); // Odstraní "api/" a nadbytečná lomítka
const bulletinFileLocation = computed(() => {
  if (!race0.value?.files) return null;

  const bulletinFile = race0.value.files.find(file => file.specific === "bulletin");
  return bulletinFile ? `${BaseUrl.value}${bulletinFile.location}` : null;
});

// Kontrola vyplněných údajů pro přihlášeného a nepřihlášeného uživatele
const isFormValid = computed(() => {
  if (!userStore.isLoggedIn) {
    // Zkontrolujte, že všechna pole jsou vyplněna
    return selectedUser.value.first_name &&
           selectedUser.value.last_name &&
           selectedUser.value.birth_year &&
           selectedUser.value.competitor_gender &&
           selectedCategoryRace0.value &&
           validEmailCheck(selectedUser.value.email) &&
           rules.siNumber(selectedUser.value.si_number) === true;
  }
  
  return selectedCategoryRace0.value && rules.siNumber(selectedUser.value.si_number) === true; // přihlášený zkontroluje jen výběr hlavní kategorie a číslo čipu
});

function validEmailCheck(email) {
  return !email || /^[\w-.]+@([\w-]+\.)+[\w-]{2,24}$/.test(email);
}


const siRentData = ref(null);

async function fetchRaceServices(eventId) {
  try {
    const response = await apiClient.get(`race_service_entry/?event_id=${eventId}`);
    accommodationsByDate.value = response.data.accommodations;
    Object.keys(accommodationsByDate.value).forEach(date => {
      accommodationsByDate.value[date].forEach(accommodation => {
        const limit = accommodation.limit !== null ? accommodation.limit : Infinity;
        const count = accommodation.count !== null ? accommodation.count : 0;
        accommodation.freeSpaces = limit !== Infinity ? limit - count : null;
      });
      selectedAccommodations.value[date] = '';
    });
    mealsByDate.value = response.data.foods;
    Object.keys(mealsByDate.value).forEach(date => {
      selectedMeals.value[date] = {};
      mealsByDate.value[date].forEach(meal => {
        selectedMeals.value[date][meal.type] = null;
      });
    });

    // Kontrola a nastavení siRentData
    if (response.data.si_rent && response.data.si_rent.length > 0) {
      siRentData.value = response.data.si_rent[0]; // Uložení prvního záznamu si_rent
    }

  } catch (error) {
    console.error('Failed to fetch race services:', error);
  }
}

// Výpočet zbývajících míst ubytování
const uniqueAccommodations = computed(() => {
  const accommodationMap = new Map();
  Object.values(accommodationsByDate.value).forEach(items => {
    items.forEach(item => {
      if (item.limit !== null && !accommodationMap.has(item.name)) {
        accommodationMap.set(item.name, item);
      }
    });
  });
  return Array.from(accommodationMap.values());
});

const submitEntries = async () => {
  let raceEntries = [];
  let serviceEntries = [];

  // Příprava základních údajů o uživateli
  const prepareEntry = (entry) => {
    if (entry.si_number === "") {
      entry.si_number = null;
    }
    return entry;
  };

  // Kontrola si_number a si_rent
  if (!event.value.disable_si_cards && !selectedUser.value.si_number && !selectedUser.value.si_rent) {
    errorMessage.value = 'Musíš vyplnit Číslo čipu nebo zaškrtnout Půjčení čipu.';
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    return;
  }

  // Přidání závodů
  if (selectedCategoryRace0.value) {
    raceEntries.push(prepareEntry({
      race: race0.value.id,
      competitor_category: selectedCategoryRace0.value,
      ...selectedUser.value
    }));
  }

  races.value.forEach(race => {
    if (selectedCategoriesForCreateEntry.value[race.id]) {
      raceEntries.push(prepareEntry({
        race: race.id,
        competitor_category: selectedCategoriesForCreateEntry.value[race.id],
        start_preference: selectedStartsEntry.value[race.id],
        ...selectedUser.value
      }));
    }
  });

  // Přidání výběru ubytování a jídla
  Object.keys(selectedAccommodations.value).forEach(date => {
    if (selectedAccommodations.value[date]) {
      serviceEntries.push(selectedAccommodations.value[date]);
    }
  });

  Object.keys(selectedMeals.value).forEach(date => {
    Object.keys(selectedMeals.value[date]).forEach(mealType => {
      if (selectedMeals.value[date][mealType]) {
        serviceEntries.push(selectedMeals.value[date][mealType]);
      }
    });
  });

  // Pokud je zaškrtnuto půjčení čipu a existuje ID pro si_rent, přidej ho do race_services
  if (selectedUser.value.si_rent && siRentData.value) {
    serviceEntries.push(siRentData.value.id); // Přidání si_rent ID k race_services
  }



  // Kontrola a odeslání přihlášek
  if (!selectedCategoryRace0.value) {
    alert('Musíte vybrat kategorii pro celou soutěž');
    return;  // Zastavení funkce, pokud není vybraná kategorie pro race0
  }

  // spuštění načítání požadavku
  isFormLoading.value = true;


  if (raceEntries.length > 0) {
    raceEntries[0].race_services = serviceEntries; // raceservices se přidají do prvního záznamu entry
    try {
      await apiClient.post(`event/${eventId}/entry/create/`, { races: raceEntries });

      // Pokud není uživatel přihlášený, přesměruj ho po úspěšném odeslání na stránku soutěže
      if (!userStore.isLoggedIn) {
        localStorage.setItem('successMessage', 'Přihláška byla úspěšně založena.');
        router.push(`/soutez/${eventId}`);
        return; // Ukončení funkce, aby se nespouštěly další části kódu
      }

      snackbar.showSnackbar("Přihláška byla úspěšně vytvořena.");
      errorMessage.value = '';
      
      await fetchEntries();
      await fetchUsers((eventId));
      await fetchRaceServices(eventId);


      // Aktualizace údajů uživatele na toho prvního v seznamu
      if (allEntries.value.length > 0) {
        selectedEntryId.value = allEntries.value[0].user;
        onEntrySelect(selectedEntryId.value); // Aktualizace formuláře s nově vybraným uživatelem
      }

      window.scrollTo({ top: 0, behavior: 'smooth' });

    } catch (error) {
      console.error('Došlo k chybě při odesílání přihlášek:', error);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
      if (error.response) {
        if (error.response.status === 423) {
          errorMessage.value = error.response.data.error || 'Limit přihlášek pro toto ubytování byl vyčerpán.';
        } else if (error.response.status === 409) {
          errorMessage.value = error.response.data.error;
        } else {
          errorMessage.value = 'Přihlášku se nepodařilo vytvořit.';
        }
      } else {
        errorMessage.value = 'Přihlášku se nepodařilo vytvořit.';
      }
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    } finally {
      isFormLoading.value = false;  // Ukončení načítání po zpracování odpovědi
    }
  }
};


// CENY   

// Přidání výpočtu celkové sumy pro editaci přihlášky
const totalSumEdit = computed(() => {
  let sum = 0;

  // Funkce pro získání správné ceny podle termin_prihl
  const getCategoryFee = (category, race) => {
    // Hlavní závod (first race) vždy používá fee_event
    if (race.id === mainRace.value.id) {
      return category.fee_event || 0; // Pokud není fee_event dostupné, vrať 0
    }
    // Ostatní závody: používá increased_fee, pokud je termin_prihl "2", jinak fee
    if (mainEntry.value && mainEntry.value.termin_prihl === "2" && category.increased_fee) {
      return category.increased_fee;
    }
    return category.fee || 0; // Pokud není fee dostupné, vrať 0
  };

  // Suma za ubytování
  Object.values(selectedAccommodations.value).forEach(id => {
    if (id) {
      const date = Object.keys(accommodationsByDate.value).find(date =>
        accommodationsByDate.value[date].some(item => item.id === id)
      );
      const accommodation = accommodationsByDate.value[date].find(item => item.id === id);
      sum += accommodation.price;
    }
  });

  // Suma za jídlo
  Object.values(selectedMeals.value).forEach(dayMeals => {
    Object.values(dayMeals).forEach(mealId => {
      if (mealId) {
        const date = Object.keys(mealsByDate.value).find(date =>
          mealsByDate.value[date].some(meal => meal.id === mealId)
        );
        const meal = mealsByDate.value[date].find(meal => meal.id === mealId);
        sum += meal.price;
      }
    });
  });

  // Suma za hlavní závod (vždy započítán)
  if (selectedCategories.value[mainRace.value.id]) {
    const category = mainRace.value.categories.find(cat => cat.id === selectedCategories.value[mainRace.value.id]);
    if (category) {
      sum += getCategoryFee(category, mainRace.value);
    }
  }

  // Suma za kategorie ostatních závodů
  Object.keys(selectedCategories.value).forEach(raceId => {
    if (raceId != mainRace.value.id && selectedCategories.value[raceId]) {
      const race = allRaces.value.find(r => r.id === parseInt(raceId));
      if (race) {
        const category = race.categories.find(cat => cat.id === selectedCategories.value[raceId]);
        if (category) {
          sum += getCategoryFee(category, race);
        }
      }
    }
  });

  // Přidání ceny za si_rent, pokud je checkbox zaškrtnutý
  if (siRentChecked.value && siRentData.value) {
    sum += siRentData.value.price; // Přidání ceny za si_rent
  }


  return sum;
});

// Přidání výpočtu celkové sumy pro vytváření přihlášky
const totalSumCreate = computed(() => {
  let sum = 0;

  // Funkce pro získání správné ceny podle entry_deadline z prvního závodu (race0)
  const getCategoryFee = (category, race) => {
    // Hlavní závod (first race) vždy používá fee_event
    if (race.id === race0.value.id) {
      return category.fee_event || 0; // Pokud není fee_event dostupné, vrať 0
    }

    // Ostatní závody: používá increased_fee, pokud je termín po deadline1, jinak fee
    const now = new Date();
    const deadline1 = new Date(race0.value.entry_deadline_1);
    const deadline2 = new Date(race0.value.entry_deadline_2);

    if (now > deadline1 && (!deadline2 || now < deadline2) && category.increased_fee) {
      return category.increased_fee;
    }
    return category.fee || 0; // Pokud není fee dostupné, vrať 0
  };

  // Suma za ubytování
  Object.values(selectedAccommodations.value).forEach(id => {
    if (id) {
      const date = Object.keys(accommodationsByDate.value).find(date =>
        accommodationsByDate.value[date].some(item => item.id === id)
      );
      const accommodation = accommodationsByDate.value[date].find(item => item.id === id);
      sum += accommodation.price;
    }
  });

  // Suma za jídlo
  Object.values(selectedMeals.value).forEach(dayMeals => {
    Object.values(dayMeals).forEach(mealId => {
      if (mealId) {
        const date = Object.keys(mealsByDate.value).find(date =>
          mealsByDate.value[date].some(meal => meal.id === mealId)
        );
        const meal = mealsByDate.value[date].find(meal => meal.id === mealId);
        sum += meal.price;
      }
    });
  });

   // Přidání ceny za půjčení čipu, pokud je vybráno a existuje si_rent
   if (selectedUser.value.si_rent && siRentData.value) {
    sum += siRentData.value.price;
  }

  // Suma za hlavní závod (vždy započítán)
  if (selectedCategoryRace0.value) {
    const category = race0.value.categories.find(cat => cat.id === selectedCategoryRace0.value);
    if (category) {
      sum += getCategoryFee(category, race0.value);
    }
  }

  // Suma za kategorie ostatních závodů
  Object.keys(selectedCategoriesForCreateEntry.value).forEach(raceId => {
    if (raceId != race0.value.id && selectedCategoriesForCreateEntry.value[raceId]) {
      const race = races.value.find(r => r.id === parseInt(raceId));
      if (race) {
        const category = race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry.value[raceId]);
        if (category) {
          sum += getCategoryFee(category, race);
        }
      }
    }
  });
  

  return sum;
});



let isSelectClicked = ref(false); // Příznak pro kontrolu kliknutí

// Funkce pro zachycení kliknutí na select
function onSelectClick(date) {
  console.log(`Došlo ke kliknutí na select pro datum ${date}`);
  isSelectClicked.value = true; // Aktivuje watcher při kliknutí
}

// Watcher sleduje změny a aplikuje je jen tehdy, když je select kliknutý
watch(() => JSON.parse(JSON.stringify(selectedAccommodations.value)), (newVal, oldVal) => {
  if (!isSelectClicked.value) {
    console.log("Změny ignorovány, nebylo kliknuto na select.");
    return;
  }

  console.log("Aktuální selectedAccommodations:", newVal);
  let changedDate = null;

  // Projdeme všechny datumy a zjistíme, kde došlo ke změně varianty
  Object.keys(newVal).forEach(date => {
    const newAccommodationId = newVal[date];
    const oldAccommodationId = oldVal ? oldVal[date] : null;

    // Pokud se hodnota změnila, zjistíme variantu
    if (newAccommodationId !== oldAccommodationId) {
      changedDate = date;
      console.log(`Změněná hodnota pro datum: ${date}, Nové ID: ${newAccommodationId}, Staré ID: ${oldAccommodationId}`);
      
      const selectedAccommodation = accommodationsByDate.value[date].find(acc => acc.id === newAccommodationId);
      if (selectedAccommodation) {
        const selectedVariant = selectedAccommodation.variant;
        console.log(`Vybraná varianta pro datum ${date}: ${selectedVariant}`);

        // Projdeme ostatní datumy a nastavíme stejnou variantu, pokud existuje
        Object.keys(accommodationsByDate.value).forEach((otherDate) => {
          // Neprovádíme změnu na poli, kde došlo ke změně, ani tam, kde je pouze jedna možnost
          if (otherDate !== changedDate && accommodationsByDate.value[otherDate].length > 1) {
            const accommodationWithSameVariant = accommodationsByDate.value[otherDate].find(
              (acc) => acc.variant === selectedVariant
            );

            // Pokud existuje ubytování se stejnou variantou, nastavíme ho
            if (accommodationWithSameVariant) {
              console.log(`Nastavuji variantu ${selectedVariant} pro datum ${otherDate}`);
              selectedAccommodations.value[otherDate] = accommodationWithSameVariant.id;
            } else {
              console.log(`Nenalezeno ubytování s variantou ${selectedVariant} pro datum ${otherDate}`);
            }
          } else if (accommodationsByDate.value[otherDate].length === 1) {
            console.log(`Datum ${otherDate} má pouze jednu možnost, změny se neprovádějí.`);
          }
        });
      } else {
        console.log(`Ubytování s ID ${newAccommodationId} nebylo nalezeno pro datum ${changedDate}`);
      }
    }
  });

  isSelectClicked.value = false; // Resetuje příznak po dokončení změn
  }, { deep: true });




///////////////////////////////////////////////////////////////
//
//          Hromadné vytvoření přihlášek
//
///////////////////////////////////////////////////////////////

const dialogBulkEntry = ref(false);



const selectedBulkUsers = ref([]); // Uchovává ID vybraných uživatelů
const bulkEntries = reactive({});  // Objekt s daty pro každého uživatele

// načtení users pro dialog
watchEffect(() => {
  if (users.value.length > 0) {
    users.value.forEach(user => {
      bulkEntries[user.id] = reactive({
        si_number: user.si_number !== null ? user.si_number : '',
        note: '',
        si_rent: false,
        selectedCategoryRace0: null
      });
    });
  }
});





const bulkUserCategories = computed(() => {
  return users.value.reduce((acc, user) => {
    if (!user || !event.value?.races || event.value.races.length === 0) return acc;

    const age = calculateUserAge(user.birth_year);
    const gender = user.competitor_gender;
    const userClub = user.competitor_club;  // Oddíl uživatele
    const eventOrganizerClub = event.value.club;  // Oddíl organizátora

    // Ověříme, zda je uživatel ze stejného oddílu jako organizátor
    const isSameClub = isUserInSameClub(userClub, eventOrganizerClub);

    // Filtrujeme kategorie přímo z API dat (event.value.races[0])
    acc[user.id] = filterCategoriesByAgeAndSex(event.value.races[0].categories, age, gender, isSameClub);
    return acc;
  }, {});
});


const bulkSelectedCategories = ref({});  // Pro uložení vybraných kategorií pro závody
const bulkSelectedStarts = ref({}); // Pro uložení startovních preferencí

// Pomocná funkce pro získání uživatele podle ID
const bulkUserRaceCategories = computed(() => {
  return users.value.reduce((acc, user) => {
    if (!user || !event.value?.races?.length) return acc;

    const age = calculateUserAge(user.birth_year);
    const gender = user.competitor_gender;
    const userClub = user.competitor_club;
    const eventOrganizerClub = event.value.club;

    // Ověříme, zda je uživatel ze stejného oddílu jako organizátor
    const isSameClub = isUserInSameClub(userClub, eventOrganizerClub);

    acc[user.id] = event.value.races.reduce((raceAcc, race) => {
      raceAcc[race.id] = filterCategoriesByAgeAndSex(race.categories, age, gender, isSameClub);
      return raceAcc;
    }, {});

    return acc;
  }, {});
});

// Watcher pro inicializaci hodnot při otevření dialogu
watch(dialogBulkEntry, (isOpen) => {
  if (isOpen) {
    users.value.forEach(user => {
      if (!bulkSelectedCategories.value[user.id]) bulkSelectedCategories.value[user.id] = {};
      if (!bulkSelectedStarts.value[user.id]) bulkSelectedStarts.value[user.id] = {};

      races.value.forEach(race => {
        bulkSelectedCategories.value[user.id][race.id] = null;
        bulkSelectedStarts.value[user.id][race.id] = "";
      });
    });
  }
});


// Reaktivní objekty pro bulk ubytování a jídlo, indexované podle user.id
const bulkSelectedAccommodations = reactive({});
const bulkSelectedMeals = reactive({});

// Inicializace bulkSelectedAccommodations a bulkSelectedMeals při otevření dialogu
watch(dialogBulkEntry, (isOpen) => {
  if (isOpen) {
    users.value.forEach(user => {
      // Inicializace bulkSelectedAccommodations pro každého uživatele
      if (!bulkSelectedAccommodations[user.id]) {
        bulkSelectedAccommodations[user.id] = {};
      }
      Object.keys(accommodationsByDate.value).forEach(date => {
        bulkSelectedAccommodations[user.id][date] = ''; // výchozí prázdná hodnota
      });
      // Inicializace bulkSelectedMeals pro každého uživatele
      if (!bulkSelectedMeals[user.id]) {
        bulkSelectedMeals[user.id] = {};
      }
      Object.keys(mealsByDate.value).forEach(date => {
        if (!bulkSelectedMeals[user.id][date]) {
          bulkSelectedMeals[user.id][date] = {};
        }
        ['Snídaně', 'Oběd', 'Večeře'].forEach(mealType => {
          bulkSelectedMeals[user.id][date][mealType] = null;
        });
      });
    });
  }
});



async function submitBulkEntries() {

    /// Validace před odesláním
    for (const userId of selectedBulkUsers.value) {
      const user = users.value.find(u => u.id === userId);
      const userData = bulkEntries[userId];
      
      // Kontrola hlavní kategorie pro race0
      if (!userData.selectedCategoryRace0) {
        errorMessage.value = `Vyber hlavní kategorii pro uživatele ${user.last_name} ${user.first_name}.`;
        return;
      }
      // Pokud event.disable_si_cards není true, ověříme, že má uživatel vyplněné si_number nebo je zaškrtnuté si_rent
      if (!event.value.disable_si_cards) {
        if ((!userData.si_number || userData.si_number === "") && !userData.si_rent) {
          errorMessage.value = `Pro uživatele ${user.last_name} ${user.first_name} musí být vyplněno číslo SI nebo zaškrtnuto půjčení čipu.`;
          return;
        }
      }
    }
  
    isFormLoading.value = true;

  let bulkRaceEntries = [];

  selectedBulkUsers.value.forEach(userId => {
    const user = users.value.find(u => u.id === userId);
    if (!user) return;
    
    const userData = bulkEntries[userId];
    
    // Základní objekt s uživatelskými údaji, který backend vyžaduje
    const baseEntry = {
      id: user.id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      index: user.index,
      competitor_index: user.competitor_index,
      competitor_gender: user.competitor_gender,
      birth_year: user.birth_year,
      gender: user.gender,
      country: user.country,
      si_number: userData.si_number === "" ? null : userData.si_number,
      note: userData.note
    };

    // Pro hlavní závod (race0)
    if (userData.selectedCategoryRace0) {
      // Sestavíme race_services – zahrneme půjčení SI a vybrané položky ubytování a jídla
      let race_services = [];
      if (userData.si_rent && siRentData.value) {
        race_services.push(siRentData.value.id);
      }
      if (bulkSelectedAccommodations[userId]) {
        Object.keys(bulkSelectedAccommodations[userId]).forEach(date => {
          const accId = bulkSelectedAccommodations[userId][date];
          if (accId) race_services.push(accId);
        });
      }
      if (bulkSelectedMeals[userId]) {
        Object.keys(bulkSelectedMeals[userId]).forEach(date => {
          const mealsForDate = bulkSelectedMeals[userId][date];
          Object.keys(mealsForDate).forEach(mealType => {
            const mealId = mealsForDate[mealType];
            if (mealId) race_services.push(mealId);
          });
        });
      }
      bulkRaceEntries.push({
        ...baseEntry,
        race: race0.value.id,
        competitor_category: userData.selectedCategoryRace0,
        race_services: race_services
      });
    }

    // Pro další závody
    races.value.forEach(race => {
      const selectedCat = bulkSelectedCategories.value[userId] && bulkSelectedCategories.value[userId][race.id];
      if (selectedCat) {
        bulkRaceEntries.push({
          ...baseEntry,
          race: race.id,
          competitor_category: selectedCat,
          start_preference: (bulkSelectedStarts.value[userId] && bulkSelectedStarts.value[userId][race.id]) || "",
          race_services: [] // U dalších závodů neposíláme race_services
        });
      }
    });
  });

  console.log("Odesílám bulk entry data:", bulkRaceEntries);

  try {
    await apiClient.post(`event/${eventId}/entry/create/`, { races: bulkRaceEntries });
    snackbar.showSnackbar("Hromadná přihláška byla úspěšně vytvořena.");
    errorMessage.value = '';
    dialogBulkEntry.value = false

    await fetchRaceServices(eventId);
    await fetchEntries();
    await fetchUsers((eventId));

  } catch (error) {
    console.error("Chyba při odesílání hromadné přihlášky:", error);
    if (error.response) {
      if (error.response.status === 423) {
        errorMessage.value = error.response.data.error || 'Limit přihlášek pro toto ubytování byl vyčerpán.';
      } else if (error.response.status === 409) {
        errorMessage.value = error.response.data.error;
      } else {
        errorMessage.value = 'Přihlášku se nepodařilo vytvořit.';
      }
    } else {
      errorMessage.value = 'Přihlášku se nepodařilo vytvořit.';
    }
  } finally {
    isFormLoading.value = false;
  } 
}





const currentPage = ref(1);
const itemsPerPage = 20;


// Opravená verze `pagedUserOptions`
const pagedUserOptions = computed(() => {
  if (!userOptions.value.length) return []; // Ochrana před undefined
  const start = (currentPage.value - 1) * itemsPerPage;
  return filteredUserOptions.value.slice(start, start + itemsPerPage);
});

// Oprava problému s načítáním dat při otevření dialogu
watch(dialogBulkEntry, (isOpen) => {
  if (isOpen) {
    if (!users.value.length) fetchUsers(eventId); // Načíst uživatele, pokud nejsou
    users.value.forEach(user => {
      if (!bulkEntries[user.id]) {
        bulkEntries[user.id] = reactive({
          si_number: user.si_number ?? '',
          note: '',
          si_rent: false,
          selectedCategoryRace0: null
        });
      }
    });
  }
});

const searchQuery = ref("");
const debouncedSearchQuery = ref("");
let debounceTimeout = null;

watch(searchQuery, (newVal) => {
  if (debounceTimeout) clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(() => {
    debouncedSearchQuery.value = newVal;
  }, 300);
});

const filteredUserOptions = computed(() => {
  // Pokud je dotaz prázdný nebo obsahuje jen 1 znak, vrátíme celý seznam
  if (!debouncedSearchQuery.value || debouncedSearchQuery.value.length < 3) {
    return userOptions.value;
  }
  // Až když je dotaz 2 a více znaků, provedeme filtrování
  return userOptions.value.filter(user =>
    user.title.toLowerCase().includes(debouncedSearchQuery.value.toLowerCase())
  );
});



// watcher na výběr stejných kategorií ve vedlejších závodech, jako je v hlavní soutěži

watch(
  () => Object.keys(bulkEntries).map(userId => ({
    userId,
    selectedCategoryRace0: bulkEntries[userId].selectedCategoryRace0
  })),
  (newValues) => {
    newValues.forEach(({ userId, selectedCategoryRace0 }) => {
      if (!selectedCategoryRace0) return;

      // Najdi název vybrané kategorie v hlavní soutěži
      const selectedCategory = bulkUserCategories.value[userId]?.find(cat => cat.id === selectedCategoryRace0);
      if (!selectedCategory) return;

      const selectedCategoryName = selectedCategory.category_name;

      // Projdi všechny závody a nastav kategorii se stejným názvem
      races.value.forEach(race => {
        const matchingCategory = (bulkUserRaceCategories.value[userId]?.[race.id] || []).find(
          cat => cat.category_name === selectedCategoryName
        );

        if (matchingCategory) {
          bulkSelectedCategories.value[userId][race.id] = matchingCategory.id;
        }
      });
    });
  },
  { deep: true }
);






</script>


<style scoped>


.text-red {
  color: red;
}


@media (max-width: 600px) {
  .responsive-btn {
    width: 100%;
    margin-bottom: 10px;
  }
  .responsive-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .price {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    color: red;
    display: block;
    font-weight: bold;
  }
}
@media (min-width: 601px) {
  .responsive-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .price {
    color: red;
    margin-left: 10px;
    margin-right: auto;
    font-weight: bold;

  }
}


/*********** Část CSS pro dialog hromadných přihlášek ****************/
/* Obal pro horizontální scrollování */
.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-gutter: stable;
  height: 75vh;
  border: none;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

}

@media (max-width: 600px) {
  .scroll-container {
    height: 65vh !important;
  }
}

/* Obsah pro roztažení */
.scroll-content {
  display: flex;
  flex-direction: column;
  min-width: max-content;
}

/* Řádek uživatele */
.user-row {
  display: flex;
  align-items: center;
  min-width: 1000px; /* Změň podle potřeb */
  padding: 3px;
  border-bottom: 1px solid #ddd;
  background-color: white;
}


/* Buňky s obsahem */
.user-cell {
  flex: 1;
  min-width: 200px; /* Minimální šířka sloupce */
  padding: 4px 6px 4px 6px;
  display: flex;
  align-items: stretch;
}


.race-select-container {
  display: flex;
  flex-direction: column; /* Položí oba selecty pod sebe */
  width:200px;
  gap: 3px; /* Přidá mezery mezi selecty */
}


</style>